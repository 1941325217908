import React from 'react';
import { BiSliderAlt } from 'react-icons/bi';
import { useScroll } from '../../../contexts/scroll';

const FilterSideButton = ({ setIsFilterOpen, isFilterOpen }: any) => {
  const { visible } = useScroll();

  const btnStyle =
    'fixed sm:flex items-center duration-300 delay-300 bg-deehiy text-white shadow-sm hover:shadow-xl justify-center  ';

  return (
    <button
      title="Show filters"
      className={`
       ${
         visible ? 'right-0' : 'right-[-150px]'
       } ${btnStyle}  bottom-[65vh] z-20 sm:z-0 sm:bottom-[60vh]  sm:left-[-10px] 
      active:bg-opacity-80 sm:hover:left-0 w-14 h-14 sm:h-12 rounded-none rounded-l-full sm:rounded-none sm:rounded-r-full shadow-xl`}
      onClick={() => setIsFilterOpen(!isFilterOpen)}
    >
      <BiSliderAlt className="h-5 w-5 mx-auto" />
    </button>
  );
};

export default FilterSideButton;
