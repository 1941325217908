import React, { useEffect, useState } from 'react';

const DownloadAppPopup = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if the banner was dismissed in the session storage
    const bannerDismissed = sessionStorage.getItem('bannerDismissed');
    if (!bannerDismissed) {
      setIsVisible(true);
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    sessionStorage.setItem('bannerDismissed', 'true');
  };

  const handleClick = () => {
    window.open('https://play.google.com/store/apps/details?id=com.deehiy.app', '_blank');
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-14 right-28 w-96 hidden sm:block bg-white shadow-xl p-4 rounded-lg z-50">
      <div className="relative">
        <button
          className="absolute top-0 left-0 mt-2 ml-2 text-deehiy opacity-80 hover:opacity-100"
          onClick={handleClose}
        >
          ✕
        </button>
        <div className="flex flex-col items-center text-center p-4">
          <h1 className="font-bold text-lg">
            Get <span className="text-deehiy">Pulse</span> app <br />
            and never miss a beat
          </h1>
          <img src="/images/download-pulse-app.svg" alt="QR Code" className=" w-36 my-4" />
          <img
            onClick={handleClick}
            src="/images/store_badge.png"
            alt="google store"
            className="w-32"
          />
        </div>
      </div>
    </div>
  );
};

export default DownloadAppPopup;
