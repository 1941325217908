import React, { useState, useEffect, useMemo } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from '@tanstack/react-table';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { CustomDropwdown, DropdownItem } from '../../../main/navigation/Navigation';
import { DropdownDotsBtn } from '../../../main/community/Community';
// import { FiEdit } from 'react-icons/fi';
import { RxCrossCircled } from 'react-icons/rx';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { BsChevronExpand } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useHunt } from '../../../../contexts/hunt';
import { Hunt } from '../../../../types/types';
import { NumericFormat } from 'react-number-format';
import { deleteHuntService } from '../../../../services/huntService';
import { useTranslation } from 'react-i18next';

const columnHelper = createColumnHelper<Hunt>();
// const headerStyle = 'px-4 w-full flex';

const EditDropdown = ({
  handleToggleStatus,
  handleDelete,
  id,
  status,
}: {
  handleToggleStatus: any;
  handleDelete: any;
  id: number;
  status: string;
}) => {
  // const navigate = useNavigate();
  const { hunts } = useHunt();
  const { t } = useTranslation();

  return (
    <CustomDropwdown Btn={<DropdownDotsBtn />}>
      {/* <Dropdown.Item
        onClick={() => navigate(`/users/edithunt/${id}`)}
        className="block text-xs font-medium truncate hover:cursor-pointer"
      >
        <FiEdit className="w-5 h-5 pr-2" />
        edit
      </Dropdown.Item> */}
      {status === 'active' ? (
        <DropdownItem
          onClick={() => handleToggleStatus(id, status, hunts)}
          itemLabel={t('close')}
        />
      ) : (
        <DropdownItem
          onClick={() => handleToggleStatus(id, status, hunts)}
          itemLabel={t('activate')}
        />
      )}
      <DropdownItem onClick={() => handleDelete(id)} itemLabel={t('delete')} />
    </CustomDropwdown>
  );
};

const HuntTable = () => {
  // const rerender = useReducer(() => ({}), {})[1];
  const [sorting, setSorting] = useState<SortingState>([]);
  const { hunts, setHuntsContext, fetchHuntsByUserIdContext, updateHuntContext } = useHunt();
  const [data, setData] = useState(() => [...hunts]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleToggleStatus = async (
    huntId: number,
    currentStatus: string,
    currentHunts: Hunt[]
  ) => {
    let newStatus = currentStatus === 'active' ? t('closed') : t('active');
    updateHuntContext({ huntId, status: newStatus })
      .then((res) => {
        const updatedHunts = currentHunts.map((hunt) => {
          if (hunt.huntId === res.data.hunt.huntId) {
            return { ...hunt, status: newStatus };
          }
          return hunt;
        });

        setHuntsContext(updatedHunts);
      })
      .catch((error) => {
        console.error('Error changing hunt status:', error);
        alert('An error occurred while changing the status');
      });
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteHuntService(id);

      await fetchHuntsByUserIdContext();
    } catch (error) {
      console.error('Error deleting the hunt:', error);
      alert('An error occurred while deleting the hunt');
    }
  };

  const columns = useMemo<ColumnDef<Hunt, any>[]>(() => {
    const baseColumns = [
      // columnHelper.accessor('isoCodeCountry', {
      //   header: () => <span className={headerStyle}>Country</span>,
      //   cell: (info) => <span className="justify-center flex w-full">{info.renderValue()}</span>,
      //   // footer: (info) => info.column.id,
      // }),
      // columnHelper.accessor((row) => row.city, {
      //   id: 'city',
      //   cell: (info) => <i>{info.getValue()}</i>,
      //   header: () => <span className={headerStyle}>City</span>,
      //   // footer: (info) => info.column.id,
      // }),
      columnHelper.accessor((row) => row.location, {
        id: 'location',
        cell: (info) => (
          <div title={`${info.getValue()}`} className="line-clamp-1">
            {info.getValue()}
          </div>
        ),
        header: () => <span className="pl-4 text-xs sm:text-md">{t('Location')}</span>,
        // footer: (info) => info.column.id,
      }),
      columnHelper.accessor('bedrooms', {
        id: 'rooms',
        header: () => <span className="pl-4">{t('Rooms')}</span>,
        cell: (info) => info.renderValue(),
        // footer: (info) => info.column.id,
      }),
      columnHelper.accessor('price', {
        id: 'price',
        header: () => <span className="pl-4">{t('Budget')}</span>,
        cell: (info) => (
          <span className="whitespace-nowrap">
            <NumericFormat
              value={info.renderValue()}
              thousandSeparator=" "
              displayType={'text'}
              prefix={info.row.original.isoCodeCurrency + ' '}
            />
          </span>
        ),

        // footer: (info) => info.column.id,
      }),
      columnHelper.accessor('propertyType', {
        id: 'propertyType',
        header: () => <span className="pl-4 text-xs sm:text-md">{t('Type')}</span>,
        cell: (info) => (
          <span className="whitespace-nowrap text-xs sm:text-md">{info.renderValue()}</span>
        ),
        // footer: (info) => info.column.id,
      }),
      columnHelper.accessor('matches', {
        id: 'listings',
        header: () => <span className="pl-4 text-xs sm:text-md">{t('Listings')}</span>,
        cell: (info) => (
          <div
            title={t('See listings')}
            onClick={() => navigate(`/users/matches/hunt/${info.row.original.huntId}`)}
            className="text-left text-xs sm:text-md font-bold cursor-pointer whitespace-nowrap"
          >
            {info.renderValue()?.length}{' '}
            {(info.renderValue() ?? []).length > 0 && (
              <span className="text-deehiy font-bold px-2 text-xs sm:text-md">
                {t('See listings')}
              </span>
            )}
          </div>
        ),

        // footer: (info) => info.column.id,
      }),
      columnHelper.accessor('status', {
        header: () => <span className="pl-4 text-xs sm:text-md">{t('Status')}</span>,
        id: 'status',
        cell: (info) => (
          <>
            {/* desktop */}
            <span
              className={`${
                info.renderValue() === 'active' ? 'bg-deehiy' : 'bg-deehiy-red'
              } text-center justify-center hidden sm:flex rounded-md py-1 px-2  font-bold text-white text-xs sm:text-md`}
            >
              {info.renderValue()}
            </span>
            {/* Mobile */}
            <span className="text-center justify-center flex sm:hidden">
              {info.renderValue() === 'active' ? (
                <AiOutlineCheckCircle className="text-deehiy w-5 h-5" />
              ) : (
                <RxCrossCircled className="text-deehiy-red w-5 h-5" />
              )}
            </span>
          </>
        ),
        // footer: (info) => info.column.id,
      }),
      columnHelper.accessor('huntId', {
        id: 'edit',
        header: '',
        cell: (info) => (
          <EditDropdown
            handleDelete={handleDelete}
            handleToggleStatus={handleToggleStatus}
            id={Number(info.renderValue())}
            status={info.row.original.status}
          />
        ),
        enableSorting: false,
      }),
    ];
    const mobileColumns = baseColumns.filter(
      (col: any) =>
        col.id === 'location' || col.id === 'status' || col.id === 'listings' || col.id === 'edit'
    ) as ColumnDef<Hunt, any>[];

    return window.innerWidth <= 768 ? mobileColumns : baseColumns;
  }, []);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    fetchHuntsByUserIdContext();
  }, []);

  useEffect(() => {
    setData(() => [...hunts]);
  }, [hunts]);

  return (
    <div>
      <div className="p-0 sm:p-2">
        <table style={{ borderCollapse: 'separate', borderSpacing: '0 10px' }} className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none flex items-center'
                            : 'hidden',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <BiChevronUp className="w-5 h-5" />,
                          desc: <BiChevronDown className="w-5 h-5" />,
                        }[header.column.getIsSorted() as string] ?? (
                          <BsChevronExpand className="ml-1 w-4 h-4 text-gray-400" />
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    className="border border-transparent
                    border-y-deehiy
                    first:border-l-deehiy
                    last:border-r-deehiy
                    bg-white
                    shadow-sm
                    text-xs
                    first:rounded-l-md last:rounded-r-md py-5 px-2 sm:px-4"
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.footer, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default HuntTable;
