import React, { useState, useEffect } from 'react';
import { sortList } from '../../../data/data';
import { useProperty } from '../../../contexts/property';
import { Dropdown } from 'flowbite-react';
import { MdOutlineSort } from 'react-icons/md';
import { Order } from '../../../types/types';
import { useTranslation } from 'react-i18next';

const SortBy = () => {
  const { filter, setFilter, debouncedFetchProperties, properties } = useProperty(); //fetchMaxListingPrice
  const { t } = useTranslation();
  const [selectedSort, setSelectedSort] = useState(sortList[3]);

  const handleOrder = (option: any) => {
    let newOrder = { column: 'createdAt', direction: 'DESC' } as Order;
    switch (option.category) {
      case sortList[0].category:
        newOrder = { column: 'listingPrice', direction: 'ASC' };
        break;
      case sortList[1].category:
        newOrder = { column: 'listingPrice', direction: 'DESC' };
        break;
      case sortList[2].category:
        newOrder = { column: 'viewCount', direction: 'DESC' };
        break;
      case sortList[3].category:
        newOrder = { column: 'createdAt', direction: 'DESC' };
        break;
      default:
        break;
    }

    setFilter({ ...filter, order: newOrder });
  };

  const handleChange = (option: any) => {
    setSelectedSort(option);
    handleOrder(option);
  };

  useEffect(() => {
    debouncedFetchProperties(filter, 1, properties);
  }, [filter?.order]);

  return (
    <div className="w-full justify-end text-sm flex text-defaultText!important px-4 sm:px-12 mt-14 sm:mt-4">
      <Dropdown
        dismissOnClick={true}
        arrowIcon={true}
        inline={true}
        label={
          <div className="flex gap-x-2 text-defaultText items-center">
            <MdOutlineSort className="h-5 w-5" />
            {t(selectedSort.title)}
          </div>
        }
      >
        {sortList.map((sortOption, index) => (
          <Dropdown.Item key={index} onClick={() => handleChange(sortOption)}>
            {t(sortOption.title)}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </div>
  );
};

export default SortBy;
