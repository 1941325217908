import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { AiOutlineClose } from 'react-icons/ai';
import { useGlobal } from '../../../contexts/global';

const ModalBody = ({ children, closeModal }: any) => {
  return (
    <div className="flex flex-col w-full h-full z-50 rounded-t-3xl text-xs bg-white">
      <div className="flex justify-start">
        <button className="text-deehiy pt-4 px-3" onClick={closeModal}>
          <AiOutlineClose className="h-6 w-6" />
        </button>
      </div>
      <div className=" pt-6">{children}</div>
    </div>
  );
};

const MobileModal = ({ isModalOpen, setIsModalOpen, children }: any) => {
  const { modalRootRef } = useGlobal();
  const [visible, setVisible] = useState(false); // State to control animation visibility

  const closeModal = () => {
    // Set visible to false for smooth animation out
    setVisible(false);
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleEscapeKey = (event: any) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    window.addEventListener('keydown', handleEscapeKey);

    return () => {
      window.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  useEffect(() => {
    // Update visible state to match isModalOpen
    setVisible(isModalOpen);
  }, [isModalOpen]);

  return (
    <>
      {isModalOpen && modalRootRef.current
        ? ReactDOM.createPortal(
            <div className="mt-auto w-full bottom-0 fixed z-50">
              <div
                className={
                  'fixed overflow-hidden z-40 bg-gray-900 bg-opacity-30 inset-0 transform ease-in-out ' +
                  (visible
                    ? ' transition-opacity opacity-100 duration-200 translate-y-0  '
                    : ' transition-opacity opacity-0 duration-200 translate-y-full ')
                }
              >
                <div
                  className={
                    ' rounded-t-2xl  max-h-lg absolute bottom-0  w-full shadow-xl  ease-in-out duration-500 transform  ' +
                    (visible ? ' h-[90vh]' : '  h-0 ')
                  }
                >
                  <ModalBody children={children} closeModal={closeModal} />
                </div>
                <div
                  className=" w-screen h-full cursor-pointer "
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                ></div>
              </div>
            </div>,
            modalRootRef.current
          )
        : null}
    </>
  );
};

export default MobileModal;
