import React, { useState, useEffect } from 'react';
import Toggle2 from '../../form/Toggle2';
import Input from '../../form/Input';
import { propertyTypesInitialState, countries } from '../../../data/data';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { specificsFormSchema } from '../../../lib/yup';

import { NumericFormat } from 'react-number-format';
import { Specifics, ParamsPerType } from '../../../types/types';

import Tooltip from '../../form/Tooltip';
import { useTranslation } from 'react-i18next';
import { getCountryAreaUnit } from '../../dashboard/tabsContents/ListingBasicContent';

export const SellerFinance = ({
  handleChange,
  value,
}: {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: any;
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex">
      <input
        className='w-5 h-5 text-deehiy cursor-pointer bg-white outline-none  focus:outline-transparent focus:ring-transparent border-gray-300 rounded  dark:focus:ring-deehiy dark:bg-gray-700 dark:border-gray-600"'
        type="checkbox"
        name="hasSellerFinance"
        onChange={handleChange}
        checked={value}
      />
      <div className=" text-justify w-full flex flex-wrap gap-x-4 font-normal">
        <span className="whitespace-nowrap">&nbsp;&nbsp;{t('Seller finance available')}</span>{' '}
      </div>
    </div>
  );
};

export const SellerFinanceCheckBox = ({
  handleChange,
  value,
}: {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: any;
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center gap-x-1">
      <SellerFinance handleChange={handleChange} value={value} />
      <Tooltip text={t('tooltip_seller_finance')} />
    </div>
  );
};

const SelectSpecifics: React.FC<any> = ({ editedProperty, setEditedProperty, setCanClickNext }) => {
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(specificsFormSchema),
  });
  const { t } = useTranslation();

  const [currency, setCurrency] = useState('');
  const [specificsParams, setSpecificsParams] = useState<Specifics | any>({
    bedrooms: true,
    bathrooms: true,
    homeSize: true,
    lotSize: true,
    // listingPrice: true,
    // monthlyRentPrice: true,
  });
  const areaUnit = getCountryAreaUnit(editedProperty.isoCodeCountry);

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setEditedProperty({ ...editedProperty, [name]: value });
  };

  const handlePriceChange = (e: any) => {
    let value = e.target.value; // string
    const name = e.target.name;
    const formatted = Number(value.replace(/ /g, ''));
    setEditedProperty({ ...editedProperty, [name]: formatted });
  };

  function checkParams(params: any, obj: any) {
    if (
      (params.bedrooms && !obj.bedrooms) ||
      (params.bathrooms && !obj.bathrooms) ||
      (params.homeSize && !obj.homeSize) ||
      (params.lotSize && !obj.lotSize) ||
      (obj.isForSale && !obj.listingPrice) ||
      (obj.isForRent && !obj.monthlyRentPrice)
    ) {
      return false;
    }

    return true;
  }

  useEffect(() => {
    // TODO use a switch for each type

    const isChecked = checkParams(specificsParams, editedProperty);

    // setCanClickNext(editedProperty.listingPrice ? true : false);
    setCanClickNext(isChecked);
  }, [
    editedProperty.listingPrice,
    editedProperty.monthlyRentPrice,
    editedProperty.bedrooms,
    editedProperty.bathrooms,
    editedProperty.homeSize,
    editedProperty.lotSize,
  ]);

  useEffect(() => {
    const isChecked = checkParams(specificsParams, editedProperty);
    setCanClickNext(isChecked);
  }, []);

  useEffect(() => {
    const selectedType = propertyTypesInitialState.find(
      (el: ParamsPerType) => el.propertyType === editedProperty.propertyType
    );
    setSpecificsParams(selectedType?.specifics);
  }, [editedProperty.propertyType]);

  useEffect(() => {
    const curr = countries.find(
      (option: any) => option.currency === editedProperty.isoCodeCurrency
    );
    if (curr) {
      setCurrency(curr.currency);
    }
  }, [editedProperty.isoCodeCurrency]);

  return (
    <div className="step-form-wrapper flex-col w-full">
      <FormProvider {...methods}>
        <form className="flex flex-col space-y-3">
          <div className="flex flex-col">
            {editedProperty.isForSale || editedProperty.isOffPlan ? (
              <>
                <div className="flex">
                  <div className="w-1/2">
                    <label
                      htmlFor="listingPrice"
                      className="block text-sm sm:text-md lg:text-md font-normal "
                    >
                      {t('Sales price in')} <strong> &nbsp;{currency}</strong>
                    </label>
                    <NumericFormat
                      id="listingPrice"
                      className="w-full focus:ring-0 rounded border-gray-300 focus:border-gray-300"
                      name="listingPrice"
                      onChange={handlePriceChange}
                      value={editedProperty.listingPrice}
                      thousandSeparator=" "
                      placeholder="0"
                    />
                  </div>
                  <div className="w-1/2 flex flex-col semibold px-6 text-sm gap-y-1">
                    <span>{t('Hide price')}</span>
                    <Toggle2
                      setIsToggled={() =>
                        setEditedProperty({ ...editedProperty, isPOR: !editedProperty.isPOR })
                      }
                      isToggled={editedProperty.isPOR}
                    />
                  </div>
                </div>
                <div className="text-sm flex items-center pt-4 sm:pt-2 mb-3">
                  <SellerFinanceCheckBox
                    value={editedProperty.hasSellerFinance}
                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setEditedProperty({
                        ...editedProperty,
                        hasSellerFinance: e.target.checked,
                      });
                    }}
                  />
                </div>
              </>
            ) : (
              ''
            )}
            {editedProperty.isForRent && (
              <div className="flex">
                <div className="w-1/2 pt-2">
                  <label
                    htmlFor="listingPrice"
                    className="block text-sm sm:text-md lg:text-md font-normal "
                  >
                    {t('Rental price / month in')} <strong> &nbsp;{currency}</strong>
                  </label>
                  <NumericFormat
                    id="monthlyRentPrice"
                    className="w-full focus:ring-0 rounded border-gray-300 focus:border-gray-300"
                    name="monthlyRentPrice"
                    onChange={handlePriceChange}
                    value={editedProperty.monthlyRentPrice}
                    thousandSeparator=" "
                    placeholder="0"
                  />
                </div>
              </div>
            )}
            {editedProperty.hasDailyRental && (
              <div className="flex">
                <div className="w-1/2 pt-2">
                  <label
                    htmlFor="listingPrice"
                    className="block text-sm sm:text-md lg:text-md font-normal "
                  >
                    {t('Rental price / day in')} <strong> &nbsp;{currency}</strong>
                  </label>
                  <NumericFormat
                    id="dailyRentPrice"
                    className="w-full focus:ring-0 rounded border-gray-300 focus:border-gray-300"
                    name="dailyRentPrice"
                    onChange={handlePriceChange}
                    value={editedProperty.dailyRentPrice}
                    thousandSeparator=" "
                    placeholder="0"
                  />
                </div>
              </div>
            )}
          </div>

          <div
            className={`${
              specificsParams.bathrooms && specificsParams.bathrooms
                ? 'flex w-full sm:w-1/2'
                : 'hidden'
            }`}
          >
            <div className="w-1/2 flex flex-col">
              <label
                htmlFor="bedrooms"
                className="flex gap-x-4 text-sm sm:text-md lg:text-md font-normal"
              >
                {t('Bedrooms')}
              </label>
              <select
                id="bedrooms"
                name="bedrooms"
                onChange={handleChange}
                value={editedProperty.bedrooms}
                className="mt-1 block w-2/3 text-center py-2 gap-y-1 text-base border-gray-300  focus:outline-none focus:ring-deehiy focus:border-deehiy sm:text-sm rounded-md"
              >
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">&nbsp;&nbsp;9+</option>
                {/* Add more options as needed */}
              </select>
            </div>

            <div className="w-1/2 flex flex-col">
              <label
                htmlFor="bathrooms"
                className="flex gap-x-4 text-sm sm:text-md lg:text-md font-normal"
              >
                {t('Bathrooms')}
              </label>
              <select
                id="bathrooms"
                name="bathrooms"
                onChange={handleChange}
                value={editedProperty.bathrooms}
                className="mt-1 block w-2/3 text-center py-2 gap-y-1 text-base border-gray-300 focus:outline-none focus:ring-deehiy focus:border-deehiy sm:text-sm rounded-md"
              >
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">&nbsp;&nbsp;9+</option>
                {/* Add more options as needed */}
              </select>
            </div>
          </div>

          <div className={`${specificsParams.homeSize ? 'flex' : 'hidden'}`}>
            <div className="w-1/2">
              <Input
                label={t(areaUnit.homeUnitDescription || '')}
                id="homeSize"
                name="homeSize"
                type="number"
                onChange={handleChange}
                value={editedProperty.homeSize}
                placeholder={'0'}
              />
            </div>
          </div>

          <div className={`${specificsParams.lotSize ? 'flex' : 'hidden'}`}>
            <div className="w-1/2">
              <Input
                label={t(areaUnit.landUnitDescription || '')}
                id="lotSize"
                name="lotSize"
                type="number"
                onChange={handleChange}
                value={editedProperty.lotSize}
                placeholder={'0'}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default SelectSpecifics;
