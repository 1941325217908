import React, { useState, useEffect } from 'react';
import { useProperty } from '../../../contexts/property';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/auth';
import Loader from '../../main/Loader';
import VerticalTab from '../tabsContents/VerticalTab';
import { EditListingTab } from '../../../types/types';
import OwnerContent from '../tabsContents/OwnerContent';

// tabs contents
import PriceContent from '../tabsContents/PriceContent';
import AvailabilityContent from '../tabsContents/AvailabilityContent';
import LocationContent from '../tabsContents/LocationContent';
import ListingBasicContent from '../tabsContents/ListingBasicContent';
import FeaturesContent from '../tabsContents/FeaturesContent';
import ImagesContent from '../tabsContents/ImagesContent';
import UpdateStatusContent from '../tabsContents/adminTabs/UpdateStatusContent';
import AdminLabel from './AdminLabel';

import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminEditListing = () => {
  const { fetchPropertyById, setCurrentProperty, currentProperty, adminModifyProperty } =
    useProperty();
  const { id } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const tabs: EditListingTab[] = [
    {
      id: 'tab1',
      title: 'Price',
      content: <PriceContent modifyProperty={adminModifyProperty} />,
    },
    {
      id: 'tab2',
      title: 'Listing basics',
      content: <ListingBasicContent modifyProperty={adminModifyProperty} />,
    },
    {
      id: 'tab3',
      title: 'Photos & plans',
      content: <ImagesContent modifyProperty={adminModifyProperty} />,
    },
    {
      id: 'tab4',
      title: 'Location',
      content: <LocationContent modifyProperty={adminModifyProperty} />,
    },
    {
      id: 'tab5',
      title: 'Features',
      content: <FeaturesContent modifyProperty={adminModifyProperty} />,
    },
    {
      id: 'tab6',
      title: 'Availability',
      content: <AvailabilityContent modifyProperty={adminModifyProperty} />,
    },
    {
      id: 'tab7',
      title: 'Owner details',
      content: <OwnerContent />,
    },
    {
      id: 'tab8',
      title: 'Update status',
      content: <UpdateStatusContent modifyProperty={adminModifyProperty} />,
    },
  ];

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      // authentication check
      fetchPropertyById(Number(id)).then((listing: any) => {
        if (currentUser?.roles !== 'admin') {
          toast.error('for administrator only!');
          navigate('/');
        } else {
          // TODO: set images as object array or string array from API response
          const imagesStrArray = listing.images?.map((img: any) => (img.url ? img.url : img));
          setCurrentProperty({ ...listing, images: imagesStrArray });
        }
        setIsLoading(false);
      });
    }
  }, [id]);

  if (isLoading) return <Loader />;

  return (
    <div className="container">
      <AdminLabel />
      <div className=" flex  justify-between py-16 px-20">
        <div className="text-3xl font-bold w-3/5 px-22">{currentProperty?.propertyTitle}</div>

        {/* publication status */}
        <div className="flex items-center justify-center gap-4">
          {currentProperty?.published ? (
            <>
              <div className="w-5 h-5  bg-green-400 rounded-full" />
              <span className="text-gray-500">Listing published</span>
            </>
          ) : (
            <>
              <div className="w-5 h-5 bg-orange-400 rounded-full" />
              <span className="text-gray-500">Listing not published</span>
            </>
          )}
        </div>

        {/* preview listing button */}
        <button
          type="button"
          className="rounded-xl py-2 px-3 font-bold bg-gray-100 hover:opacity-80 shadow-sm hover:shadow-none border border-gray-400"
          onClick={() => window.open(`/properties/${currentProperty.propertyId}`, '_blank')}
        >
          Preview listing
        </button>
      </div>
      <VerticalTab tabs={tabs} />
      <ToastContainer transition={Slide} />
    </div>
  );
};

export default AdminEditListing;
