import React from 'react';
import TakeMeBack from '../main/navigation/TakeMeBack';
import { useTranslation } from 'react-i18next';

const HuntCreatedSuccessPage = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto flex flex-col justify-center space-y-10 pt-24 items-center">
      <TakeMeBack loc="/users/huntingboard" />

      <div className="flex font-bold text-center text-2xl sm:text-5xl">{t('hunt_on')}</div>
      <div className="flex text-center text-md">{t('hunt_submitted')}</div>
    </div>
  );
};

export default HuntCreatedSuccessPage;
