import { Business } from '../types/types';
import http from './httpService';

const apiBusinessEndpoint = '/api/business';

// retrieve business by id
export const getBusinessByIdService = (businessId: number) => {
  return http.get(`${apiBusinessEndpoint}/${businessId}`);
};

// Update a Business with id
export const updateBusinessService = async (params: any) => {
  return http.patch(`${apiBusinessEndpoint}/`, { params });
};

// Add a business with id
export const createBusinessService = async (business: Business) => {
  return http.post(apiBusinessEndpoint, { business });
};

// Search business by user id
export const getBusinessByUserIdService = (userId: number) => {
  return http.get(`${apiBusinessEndpoint}/user/${userId}`);
};

// Search users by business id
export const getUsersByBusinessIdService = (businessId: number) => {
  return http.get(`${apiBusinessEndpoint}/users/${businessId}`);
};

// Search properties by business
export const getPropertiesByBusinessIdService = (businessId: number, currency: string) => {
  return http.get(`${apiBusinessEndpoint}/properties/${businessId}/${currency}`);
};

// delete from the user business Role
export const deleteBusinessUserService = (userId: number, businessId: number) => {
  return http.delete(`${apiBusinessEndpoint}/user/${userId}/${businessId}`);
};

// update business Role
export const updateUserRoleService = (userId: number, roleId: number) => {
  return http.post(`${apiBusinessEndpoint}/role/${roleId}`, { userId });
};

// create or update listing assignment
export const assignUserService = (propertyId: number, businessId: number, userId: number) => {
  return http.patch(`${apiBusinessEndpoint}/user/${propertyId}`, { userId, businessId });
};
