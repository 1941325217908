import React from 'react';
import { useAuth } from '../contexts/auth';
import { useNavigate } from 'react-router-dom';
import { useBusiness } from '../contexts/business';
import { FiChevronRight } from 'react-icons/fi';
import { useInternationalization } from '../contexts/internationalization';
import Modal from '../components/listing/Modal';
import CurrencyDropdown from '../components/main/navigation/CurrencyDropdown';
import { adjustImageSize } from '../utils/adjustImageSize';
import { useTranslation } from 'react-i18next';
import TranslationDropdown from '../components/main/TranslationDropdown';

export const DashboardCard = ({
  title,
  subTitle,
  icon,
  url,
  isComingSoon,
  canClick = true,
}: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigation = () => {
    if (title === 'Messaging') {
      window.open(url, '_blank');
    } else if (canClick) {
      navigate(url);
    }
  };

  return (
    <div
      onClick={handleNavigation}
      className={`${
        !canClick ? 'cursor-not-allowed' : ' cursor-pointer hover:shadow-xl'
      } text-xs relative shadow-md cursor-pointer bg-white p-4 sm:p-8 rounded-lg flex-col  duration-500 `}
    >
      {isComingSoon && (
        <div className="absolute w-full top-[0] sm:top-[-0.8em]">
          <div className="flex w-full justify-evenly">
            <img
              src={'/images/icons/icon-dashboard-landing/coming-soon.svg'}
              alt="ico"
              className="h-8 sm:h-10 md:h-14"
            />
          </div>
        </div>
      )}
      <img src={icon} alt="ico" className="w-9 h-9 sm:w-14 sm:h-14" />
      <div className="font-bold">{t(title)}</div>
      <div className="font-light text-[11px] sm:text-xs">{t(subTitle)}</div>
    </div>
  );
};

export const MobileProfileItem = ({ imageUrl, url, title, subtitle }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div
      onClick={() => navigate(url)}
      className="active:bg-gray-200 duration-300 w-full py-4 flex items-center justify-between hover:cursor-pointer"
    >
      <div className="gap-x-4 flex items-center">
        <img alt="" src={adjustImageSize(imageUrl)} className="rounded-full w-12 h-12" />
        <div className="flex flex-col">
          <div className="font-bold">{t(title)}</div>
          <div className="text-sm font-normal">{t(subtitle)}</div>
        </div>
      </div>
      <FiChevronRight className="text-deehiy h-9 w-9" />
    </div>
  );
};

export const DashboardMobileListItem = ({ imageUrl, url, title }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div
      onClick={() => navigate(url)}
      className="active:bg-gray-200 duration-300 flex py-4 items-center justify-between hover:cursor-pointer"
    >
      <div className="gap-x-4 flex items-center">
        <img alt="" src={imageUrl} className="w-9 h-9" />
        <div className="font-bold">{t(title)}</div>
      </div>
      <FiChevronRight className="text-deehiy h-9 w-9" />
    </div>
  );
};

export const Divider = () => (
  <div className="w-full pt-2 pb-3 border border-transparent border-b-deehiy"></div>
);

const Btn = ({ setShowModal }: any) => {
  const { currency } = useInternationalization();

  const handleClick = async () => {
    setShowModal(true);
  };

  return (
    <button onClick={handleClick} className="flex cursor-pointer px-2">
      {currency}
    </button>
  );
};

const DashboardHomePage = () => {
  const { currentUser, logoutUser } = useAuth();
  const { currentBusiness } = useBusiness();
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const appToken = localStorage.getItem('@App:token');

  return (
    <div className="container mx-auto pb-20 px-4 sm:px-0">
      <div className="py-4 sm:py-12 font-bold justify-center sm:justify-between px-0 sm:px-20 flex flex-col sm:flex-row">
        <div className="font-bold py-2 sm:py-8">
          <div className="text-2xl sm:text-5xl pb-12 sm:pb-0">{t('Hey there!')}</div>

          <div className="hidden sm:flex text-xs sm:text-xl pt-2 sm:pt-4">
            {currentUser?.firstName} {t('what would you like to do?')}
          </div>
        </div>
        {/* // desktop */}
        <div className="hidden sm:flex mt-auto">
          {currentBusiness.businessId && (
            <button
              onClick={() => navigate('/business/dashboard')}
              className="primary-btn space-x-2 flex whitespace-nowrap"
            >
              <img
                src="/images/icons/icon-dashboard-landing/business-profile.svg"
                className="w-5 h-5 sm:w-6 sm:h-6"
              />
              <span className="text-sm sm:text-base">{t('Manage your business')}</span>
            </button>
          )}
        </div>
        {/* //Mobile */}
        <div className="flex flex-col sm:hidden mt-auto w-full">
          {currentBusiness.businessId && (
            <>
              <MobileProfileItem
                title={currentBusiness.businessName}
                subtitle={t('See business profile')}
                url="/business/dashboard"
                imageUrl={
                  currentBusiness.image ||
                  '/images/icons/icon-dashboard-landing/business-profile.svg'
                }
              />
              <Divider />
            </>
          )}
        </div>
      </div>
      <div className="hidden sm:grid grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 px-4 mx-auto">
        <DashboardCard
          title={'Personal info'}
          subTitle={'Tell the world more about yourself'}
          icon={'/images/icons/icon-dashboard-landing/personal-info.svg'}
          url={'/users/profile'}
          isComingSoon={false}
        />
        <DashboardCard
          title={'Property listings'}
          subTitle={'Manage, edit and update your listings'}
          icon={'/images/icons/icon-dashboard-landing/listings.svg'}
          url={'/users/managelistings'}
          isComingSoon={false}
        />
        {/* <DashboardCard
          title={'Network'}
          subTitle={'Explore a world of opportunities'}
          icon={'/images/icons/icon-dashboard-landing/network.svg'}
          url={'/network'}
          isComingSoon={true}
        /> */}
        {/* <DashboardCard
          title={'Messaging'}
          subTitle={'Check your inbox for new messages'}
          icon={'/images/icons/icon-dashboard-landing/messaging.svg'}
          url={`${process.env.REACT_APP_MESSENGER_URL}?token=${appToken}`}
          isComingSoon={false}
        /> */}
        <DashboardCard
          title={'Hunting board'}
          subTitle={'Manage hunts and leads'}
          icon={'/images/icons/icon-dashboard-landing/hunting-board.svg'}
          url={'/users/huntingboard'}
          isComingSoon={false}
        />
        {/* <DashboardCard
          title={'Forms'}
          subTitle={'Create contracts with ease online'}
          icon={'/images/icons/icon-dashboard-landing/forms.svg'}
          url={'/forms'}
          isComingSoon={true}
        /> */}
        {/* <DashboardCard
          title={'Analytics'}
          subTitle={'Learn how you are doing online'}
          icon={'/images/icons/icon-dashboard-landing/analytics.svg'}
          url={'/comingsoon'}
          isComingSoon={true}
        /> */}
        <DashboardCard
          title={'Login & Security'}
          subTitle={'Protect yourself from any parasites'}
          icon={'/images/icons/icon-dashboard-landing/login-security.svg'}
          url={'/users/loginandsecurity'}
          isComingSoon={false}
        />
        {/* <DashboardCard
          title={'Notifications'}
          subTitle={'Set your notifications preferences'}
          icon={'/images/icons/icon-dashboard-landing/notifications.svg'}
          url={'/users/notifications'}
          isComingSoon={false}
        /> */}
      </div>
      <div className="flex flex-col sm:hidden">
        <DashboardMobileListItem
          url={'/users/profile'}
          imageUrl={'/images/icons/icon-dashboard-landing/mobile/personal-profile.svg'}
          title="Personal info"
        />
        <DashboardMobileListItem
          url={'/users/managelistings'}
          imageUrl={'/images/icons/icon-dashboard-landing/mobile/manage-listing.svg'}
          title="Property listings"
        />
        <DashboardMobileListItem
          url={'/users/huntingboard'}
          imageUrl={'/images/icons/icon-dashboard-landing/mobile/hunting-board.svg'}
          title="Hunting board"
        />
        <DashboardMobileListItem
          url={'/users/loginandsecurity'}
          imageUrl={'/images/icons/icon-dashboard-landing/mobile/settings.svg'}
          title="Settings"
        />
        <Divider />
      </div>
      <div className="justify-between items-center flex sm:hidden w-full px-12 h-24">
        <TranslationDropdown />

        <Modal Btn={Btn}>
          <CurrencyDropdown />
        </Modal>
      </div>
      <button
        onClick={logoutUser}
        className="flex sm:hidden mx-auto rounded-xl mb-20 sm:mb-12 mt-8 border border-deehiy px-24 py-2 "
      >
        {t('Logout')}
      </button>
    </div>
  );
};

export default DashboardHomePage;
