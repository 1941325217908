import React from 'react';
import { PromotedProfile } from '../../types/types';

interface IPropPromotedCard {
  card: PromotedProfile;
}

const PromotedCard = ({ card }: IPropPromotedCard) => {
  return (
    <div
      className="market-card bg-white rounded-none flex items-center justify-center"
      key={card.id}
      onClick={() => window.open(card.link, '_blank')}
    >
      <img src={card.image} />
    </div>
  );
};

export default PromotedCard;
