import React, { useState, createContext, useContext, useEffect } from 'react';
import i18n from './../i18n';
import { getBrowserLanguage } from '../utils/getbrowserLanguage';

const initialInterContext = {
  currency: 'THB',
  setCurrency: () => {},
  locale: 'en',
  setLocale: () => {},
};

type InterContextType = {
  currency: string;
  setCurrency: React.Dispatch<React.SetStateAction<string>>;
  locale: string;
  setLocale: React.Dispatch<React.SetStateAction<string>>;
};

export const InterContext = createContext<InterContextType>(initialInterContext);

export const InterProvider = ({ children }: any) => {
  const storedCurrency = localStorage.getItem('currency');
  const storedLanguage = localStorage.getItem('language');

  const [currency, setCurrency] = useState(storedCurrency || 'THB');
  // const [locale, setLocale] = useState(i18n.language);
  const [locale, setLocale] = useState(storedLanguage || getBrowserLanguage());

  const value = { currency, setCurrency, locale, setLocale };

  useEffect(() => {
    localStorage.setItem('currency', currency);
  }, [currency]);

  useEffect(() => {
    localStorage.setItem('language', locale);
    i18n.changeLanguage(locale);
  }, [locale]);

  return <InterContext.Provider value={value}>{children}</InterContext.Provider>;
};

export function useInternationalization() {
  const context = useContext(InterContext);

  return context;
}
