import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useTranslation } from 'react-i18next';

interface LocationSearchProps {
  locationString: string;
  setLocationString: any;
  label: string;
  // { city: ''; country: ''; latitude: 0; longitude: 0, postalCode }
  setLocationObject?: (loc: any) => void;
}

const LocationSearch: React.FC<LocationSearchProps> = ({
  locationString,
  setLocationString,
  label,
  setLocationObject,
}) => {
  const { t } = useTranslation();

  const handleChange = (searchedLocation: string) => {
    setLocationString(searchedLocation);
  };

  const handleSelect = async (searchedCity: string) => {
    try {
      const results = await geocodeByAddress(searchedCity);

      const addressComponents = results[0].address_components;
      let componentCity = '';
      let componentCountry = '';
      let componentPostalCode = '';

      // Loop through address components to find the city, country, and postal code
      addressComponents.forEach((component) => {
        const componentType = component.types[0];

        if (
          componentType === 'locality' ||
          componentType === 'sublocality' ||
          componentType === 'sublocality_level_1' ||
          componentType === 'administrative_area_level_1' ||
          componentType === 'postal_town'
        ) {
          componentCity = component.long_name;
        }

        if (componentType === 'country') {
          componentCountry = component.short_name;
        }

        if (componentType === 'postal_code') {
          componentPostalCode = component.long_name;
        }
      });

      const { lat, lng } = await getLatLng(results[0]);
      const locationObject = {
        city: componentCity,
        country: componentCountry,
        postalCode: componentPostalCode,
        latitude: lat,
        longitude: lng,
      };

      if (setLocationObject) {
        setLocationObject(locationObject);
      }

      console.log('Success:', location);
    } catch (error) {
      console.error('Error:', error);
    }

    // setCity(city);
  };

  return (
    <PlacesAutocomplete
      value={locationString}
      // searchOptions={{ types: ['(cities)'] }}
      // searchOptions={{ componentRestrictions: { country: 'th' } }}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="w-full">
          <label className="block text-sm sm:text-md lg:text-md font-normal">{t(label)}</label>
          <div className="relative ">
            <input
              {...getInputProps({
                placeholder: t('search a location'),
                className: 'form-field block w-full rounded-md shadow-sm',
              })}
            />
            <div className="autocomplete-dropdown-container sticky z-10">
              {loading && <div>{t('Loading...')}</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: '#f2fdfb',
                      padding: '2px',
                      cursor: 'pointer',
                    }
                  : { backgroundColor: '#ffffff', padding: '2px', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                      onClick: () => handleChange(suggestion.description),
                    })}
                    key={suggestion.description}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearch;
