import React, { useState } from 'react';
import FilterSideButton from '../main/filter/FilterSideButton';
import FilterContainer from '../main/filter/FilterContainer';
import Modal2 from '../listing/Modal2';
import { useGlobal } from '../../contexts/global';

const AdaptiveSidebarLayout = ({ Filter, children }: any) => {
  const isMobile = window.innerWidth <= 768;
  const [isFilterOpen, setIsFilterOpen] = useState(!isMobile);
  const { isWL } = useGlobal(); // is White-label

  return (
    <div className="h-full">
      {/* desktop  */}
      <div className="flex">
        <FilterSideButton setIsFilterOpen={setIsFilterOpen} isFilterOpen={isFilterOpen} />

        <div
          className={` hidden sm:flex sticky ${
            isWL ? 'top-24' : 'top-14'
          } z-10 shadow-xl h-[calc(100vh-6rem)] bg-white duration-1000 ease-in-out ${
            // className={` hidden sm:flex sticky top-24 z-10 shadow-xl h-[calc(100vh-3.5rem)] bg-white duration-1000 ease-in-out ${
            isFilterOpen ? 'w-[300px]' : 'w-0 '
            // isFilterOpen ? 'w-1/6' : 'w-0 '
          }`}
        >
          {isMobile ? (
            <Modal2 showModal={isFilterOpen} setShowModal={setIsFilterOpen}>
              <Filter setIsFilterOpen={setIsFilterOpen} />
            </Modal2>
          ) : (
            <div className={`${isFilterOpen ? 'opacity-100 delay-500 ' : 'opacity-0 w-0'} `}>
              <FilterContainer setIsFilterOpen={setIsFilterOpen} title="Filters">
                <Filter setIsFilterOpen={setIsFilterOpen} />
              </FilterContainer>
            </div>
          )}
        </div>
        <div className={`flex sm:hidden`}></div>

        <div className={`flex-1 ${!isFilterOpen && 'xl:ml-12'}`}>{children}</div>
      </div>
    </div>
  );
};

export default AdaptiveSidebarLayout;
