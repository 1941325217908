import React from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { HiExclamationCircle } from 'react-icons/hi';

export type InputProps = {
  label: string;
  name?: string;
  id: string;
  placeholder?: string;
  helperText?: string;
  type?: string;
  readOnly?: boolean;
  validation?: any;
  // isEyeIconVisible?: boolean;
  Tooltip?: React.ComponentType;
} & React.ComponentPropsWithoutRef<'input'>;

export default function Input({
  label,
  placeholder = '',
  helperText,
  id,
  type = 'text',
  readOnly = false,
  validation,
  Tooltip,
  ...rest
}: InputProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="w-full">
      <label htmlFor={id} className="flex gap-x-4 text-sm sm:text-md lg:text-md font-normal">
        {label} {Tooltip && <Tooltip />}
      </label>
      <div className="relative mt-1">
        <input
          {...register(id, validation)}
          {...rest}
          type={type}
          name={id}
          id={id}
          readOnly={readOnly}
          placeholder={placeholder}
          aria-describedby={id}
          className={clsx(
            'block w-full shadow-sm',
            readOnly
              ? ' login-input-readOnly'
              : ` ${
                  id === 'email' || id === 'firstName' || id === 'lastName'
                    ? ' login-input text-[12px] sm:text-[14px] md:text-[16px]'
                    : ' login-input-manual-location text-[12px] sm:text-[14px] md:text-[16px]'
                }`,
            {
              'login-input-error': errors[id], // Apply focus ring only if there are errors
              'border-b-red-500': errors[id], // Apply red bottom border only if there are errors
            }
          )}
        />

        {errors[id] && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <HiExclamationCircle className="text-xl text-red-500" />
          </div>
        )}
      </div>
      <div className="mt-1">
        {helperText && <p className="text-xs text-gray-500">{helperText}</p>}
        {errors[id] && (
          <span className="text-[12px] sm:text-sm text-red-500">
            {/* {errors[id].message}  */}
            {errors[id]?.message?.toString()}
          </span>
        )}
      </div>
    </div>
  );
}
