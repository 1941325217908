import React, { useState, useEffect } from 'react';
import { EditListingTab } from '../../../types/types';
import TopMobileNavigation from '../../main/navigation/TopMobileNavigation';
import { FiChevronLeft } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

interface VerticalTabsProps {
  tabs: EditListingTab[];
  setSelectedTab?: any;
  selectedTabIndex?: number;
}

const VerticalTab = ({ tabs, setSelectedTab, selectedTabIndex }: VerticalTabsProps) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(selectedTabIndex ? tabs[selectedTabIndex] : null);
  const isMobile = window.innerWidth <= 768;
  const [isEditingTab, setIsEditingTab] = useState(false);

  const handleBack = () => {
    setActiveTab(null);
  };

  useEffect(() => {
    if (setSelectedTab) {
      setSelectedTab(activeTab);
    }
    setIsEditingTab(true);

    if (activeTab === null) {
      setIsEditingTab(false);
    }
  }, [activeTab]);

  useEffect(() => {
    setIsEditingTab(false);
  }, []);

  if (isMobile) {
    return (
      <div>
        <TopMobileNavigation />
        {!isEditingTab ? (
          <>
            {tabs.map((tab: EditListingTab) => (
              <div key={tab.id} className="w-full">
                <button
                  className={`w-full py-4 text-center ${
                    activeTab?.id === tab.id ? 'bg-gray-200' : ''
                  }`}
                  onClick={() => setActiveTab(tab)}
                >
                  {t(tab.title)}
                </button>
              </div>
            ))}
          </>
        ) : (
          <div className="flex-grow w-full text-sm">
            <button
              className="h-12 text-deehiy flex w-full gap-x-2justify-start px-2 font-bold"
              onClick={handleBack}
            >
              <FiChevronLeft className="text-deehiy h-5 w-5" />
              {t('back')}
            </button>
            <div className="font-bold px-4 pt-8 sm:pt-0 pb-5">
              {activeTab && t(activeTab?.title)}
            </div>
            <div className="px-4">{activeTab?.content}</div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col sm:flex-row px-2 sm:px-20 ">
      <div className="flex flex-col snap-x overflow-x-auto w-full sm:w-1/4 sm:space-y-6 border-l-4">
        {tabs.map((tab: EditListingTab) => (
          <button
            key={tab.id}
            className={`py-2 whitespace-nowrap snap-center text-xs sm:text-base px-2 sm:px-8 text-center sm:text-left ${
              activeTab?.id === tab.id
                ? ' decoration-2 sm:decoration-1 underline decoration-deehiy sm:decoration-gray-400 underline-offset-4'
                : ''
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {t(tab.title)}
          </button>
        ))}
      </div>
      <div className="flex-grow w-full text-sm">
        <div className="font-bold hidden sm:flex pt-8 sm:pt-0 pb-5">
          {activeTab && t(activeTab?.title)}
        </div>
        {activeTab?.content}
      </div>
    </div>
  );
};

export default VerticalTab;
