import React, { useState } from 'react';
import { useAuth } from '../../contexts/auth';
import { useChat } from '../../contexts/chat';
import { useNavigate, useLocation } from 'react-router-dom';
// import { interestsList } from '../../data/data';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AiFillHeart } from 'react-icons/ai';
import { POST_TYPES } from '../../constants/constant';
import { BsDot, BsChatDots, BsFillShareFill } from 'react-icons/bs';
// import { RiDeleteBin6Line } from 'react-icons/ri';
// import { CiSquarePlus, CiFaceSmile } from 'react-icons/ci';
// import { PiImageLight } from 'react-icons/pi';
import { timeAgo } from '../../utils/dateFormatter';
import { DropdownItem, CustomDropwdown } from '../main/navigation/Navigation';
import { adjustImageSize } from '../../utils/adjustImageSize';
// import { initialUser } from '../../initialStates/initialStates';
// import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
// import OpenGalleryBtn from '../form/OpenGalleryBtn';

// import Modal2 from '../listing/Modal2';
// import { POST_TYPES } from './../../constants/constant';
// import ImageUploading, { ImageListType } from 'react-images-uploading';
// import EMTextArea from './EMTextArea';
import FullPortalModal from '../listing/FullPortalModal';
import Slideshow from '../listing/Slideshow';
// import DOMPurify from 'dompurify';
// import Loader from '../main/Loader';
// import { PostType } from '../../types/types';
import Tooltip from '../form/Tooltip';

import { blockUserService } from '../../services/communityService';

import { toggleLikePostService, deletePostService } from '../../services/postService';
import { DropdownDotsBtn } from '../main/community/Community';
import { useTranslation } from 'react-i18next';

// import ItemIcon from '../main/ItemIcon';
// import { useGlobal } from '../../contexts/global';
// import { ImagePostUpload } from '../../utils/uploader';
// import PostImageCarousel from '../main/carousel/PostImageCarousel';

interface UserTooltipProps {
  user: any; // Replace 'any' with the actual user type
}

export const UserTooltip: React.FC<UserTooltipProps> = ({ user }) => {
  const { t } = useTranslation();

  return (
    <div className="absolute border border-gray-200  bg-white w-96 px-6 py-3 hidden sm:flex flex-col rounded-xl shadow-md z-10">
      <div className="flex gap-x-3 p-3 text-lg items-center ">
        <img
          src={adjustImageSize(user.image, 64)}
          alt={`${user.firstName} ${user.lastName}`}
          className="w-16 h-16 rounded-full"
        />
        <div>{`${user.firstName} ${user.lastName}`}</div>
      </div>
      <div className="flex flex-col text-xs text-gray-700 gap-y-3 font-normal">
        <div>
          <b>{t('Location')}: </b>
          {user.city} {user.country}
        </div>
        <div>
          <b>{t('Joined')}: </b>
          {timeAgo(user.createdAt)}
        </div>
        <div>
          <b>{t('Interests')}: </b>
          {user.interests ? JSON.parse(user.interests).join(', ') : '-'}
        </div>
        <div>
          <b>{t('Skills')}: </b>
          {user.skills ? JSON.parse(user.skills).join(', ') : '-'}
        </div>
      </div>
      <div className="mx-auto py-4">
        <button onClick={() => window.open(`/member/${user.id}`, '_blank')} className="primary-btn">
          {t('Open Profile')}
        </button>
      </div>
    </div>
  );
};

export const AvatarImage = ({ imageUrl }: { imageUrl: string }) => {
  return (
    <div className="w-[40px]">
      <img
        src={adjustImageSize(imageUrl)}
        alt=""
        className={`hover:opacity-90 w-10 h-10 mb-2 rounded-full mx-auto`}
      />
    </div>
  );
};

export const PostContainer = ({ children }: any) => {
  return <div className=" bg-white shadow-md w-full">{children}</div>;
};

export const AvatarNamePost = ({ user, isNetwork, createdAt }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="flex gap-x-3 items-center text-xs xl:text-sm ">
      <div className="group relative">
        <>
          {user?.isSalesRep && (
            <div className="group absolute top-0 left-0">
              <Tooltip
                text={t('Deehiy Ambassador')}
                Component={() => <img className=" w-3 h-3 " src="/images/icons/sales-rep.svg" />}
              />
            </div>
          )}
        </>
        <AvatarImage
          imageUrl={
            user.image
              ? user.image
              : 'https://res.cloudinary.com/dpdnpljjb/image/upload/v1683867520/def_ketqlb.png'
          }
        />
        <div className="  flex group-hover:visible invisible group-hover:w-full w-0 delay-300">
          <UserTooltip user={user} />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="font-semibold flex gap-x-1 items-center group relative hover:underline cursor-pointer">
          <div onClick={() => navigate(`/member/${user.id}`)}>
            {user.firstName} {user.lastName}
            <div className="flex group-hover:visible invisible group-hover:w-full w-0 delay-300">
              <UserTooltip user={user} />
            </div>
          </div>
          {isNetwork && (
            <span className="flex gap-x-1 items-center semibold text-deehiy">
              <BsDot className="h-6 mx-auto" /> {t('Connected')}
            </span>
          )}
        </div>
        <div className="flex gap-x-1 items-center text-gray-500">
          {timeAgo(createdAt)} <BsDot className="hidden h-6 mx-auto" />
        </div>
      </div>
    </div>
  );
};

export const HeaderUser = ({ user, createdAt, children, isNetwork }: any) => {
  return (
    <div className="flex w-full justify-between pt-3 px-3">
      <AvatarNamePost user={user} isNetwork={isNetwork} createdAt={createdAt} />
      <div className="items-center flex">
        <CustomDropwdown Btn={<DropdownDotsBtn />}>{children}</CustomDropwdown>
      </div>
    </div>
  );
};

const MediaGallery = ({ media }: any) => {
  if (media.length === 1) {
    return (
      <FullPortalModal
        Btn={({ setShowModal }: any) => (
          <div className="h-full">
            <img
              src={adjustImageSize(media[0].url)}
              alt="media1"
              className="w-full h-full object-cover"
              onClick={() => setShowModal(true)}
            />
          </div>
        )}
      >
        <Slideshow list={media} defaultIndex={0} />
      </FullPortalModal>
    );
  } else if (media.length === 2) {
    return (
      <div className="flex flex-wrap h-full gap-[2px]">
        <div className="h-1/2 w-full">
          <FullPortalModal
            Btn={({ setShowModal }: any) => (
              <img
                src={adjustImageSize(media[0].url)}
                alt="media1"
                className="w-full h-full object-cover"
                onClick={() => setShowModal(true)}
              />
            )}
          >
            <Slideshow list={media} defaultIndex={0} />
          </FullPortalModal>
        </div>
        <div className="h-1/2 w-full">
          <FullPortalModal
            Btn={({ setShowModal }: any) => (
              <img
                src={adjustImageSize(media[1].url)}
                alt="media2"
                className="w-full h-full object-cover"
                onClick={() => setShowModal(true)}
              />
            )}
          >
            <Slideshow list={media} defaultIndex={1} />
          </FullPortalModal>
        </div>
      </div>
    );
  } else if (media.length === 3) {
    return (
      <div className="h-full">
        <div className="w-full h-1/2 pb-[2px]">
          <FullPortalModal
            Btn={({ setShowModal }: any) => (
              <img
                src={adjustImageSize(media[0].url)}
                alt="media1"
                className="w-full h-full object-cover"
                onClick={() => setShowModal(true)}
              />
            )}
          >
            <Slideshow list={media} defaultIndex={0} />
          </FullPortalModal>
        </div>
        <div className="h-1/2 flex flex-wrap">
          <div className="h-full">
            <div className="w-1/2 h-full pr-[1px]">
              <FullPortalModal
                Btn={({ setShowModal }: any) => (
                  <img
                    src={adjustImageSize(media[1].url)}
                    alt="media2"
                    className="w-full h-full object-cover"
                    onClick={() => setShowModal(true)}
                  />
                )}
              >
                <Slideshow list={media} defaultIndex={1} />
              </FullPortalModal>
            </div>
          </div>
          <div className="w-1/2 h-full pl-[1px]">
            <FullPortalModal
              Btn={({ setShowModal }: any) => (
                <img
                  src={adjustImageSize(media[2].url)}
                  alt="media3"
                  className="w-full h-full object-cover"
                  onClick={() => setShowModal(true)}
                />
              )}
            >
              <Slideshow list={media} defaultIndex={2} />
            </FullPortalModal>
          </div>
        </div>
      </div>
    );
  } else if (media.length === 4) {
    return (
      <div className="grid grid-cols-2 gap-[2px] h-full">
        {media.map((m: any, index: number) => (
          <FullPortalModal
            Btn={({ setShowModal }: any) => (
              <img
                className="w-full h-full object-cover"
                key={index}
                src={adjustImageSize(m.url)}
                alt={`media${index + 1}`}
                onClick={() => setShowModal(true)}
              />
            )}
          >
            <Slideshow list={media} defaultIndex={index} />
          </FullPortalModal>
        ))}
      </div>
    );
  } else if (media.length === 5) {
    return (
      <div className="h-full">
        <div className="grid grid-cols-2 h-2/3 gap-[2px] mb-[2px]">
          {media.slice(0, 2).map((m: any, index: number) => (
            <FullPortalModal
              Btn={({ setShowModal }: any) => (
                <img
                  className="w-full h-full object-cover"
                  key={index}
                  src={adjustImageSize(m.url)}
                  alt={`media${index + 1}`}
                  onClick={() => setShowModal(true)}
                />
              )}
            >
              <Slideshow list={media} defaultIndex={index} />
            </FullPortalModal>
          ))}
        </div>
        <div className="grid grid-cols-3 h-1/3  gap-[2px]">
          {media.slice(2).map((m: any, index: number) => (
            <FullPortalModal
              Btn={({ setShowModal }: any) => (
                <img
                  key={index}
                  src={adjustImageSize(m.url)}
                  alt={`media${index + 3}`}
                  className="w-full h-full object-cover"
                  onClick={() => setShowModal(true)}

                  // className="w-1/3"
                />
              )}
            >
              <Slideshow list={media} defaultIndex={index + 2} />
            </FullPortalModal>
          ))}
        </div>
      </div>
    );
  } else if (media.length > 5) {
    return (
      <div className="h-full">
        <div className="grid grid-cols-2 h-2/3 gap-[2px] mb-[2px]">
          {media.slice(0, 2).map((m: any, index: number) => (
            <FullPortalModal
              Btn={({ setShowModal }: any) => (
                <img
                  className="w-full h-full object-cover"
                  key={index}
                  src={adjustImageSize(m.url)}
                  alt={`media${index + 1}`}
                  onClick={() => setShowModal(true)}
                />
              )}
            >
              <Slideshow list={media} defaultIndex={index} />
            </FullPortalModal>
          ))}
        </div>
        <div className="grid grid-cols-3 h-1/3  gap-[2px] relative">
          {media.slice(2, 5).map((m: any, index: number) => (
            <FullPortalModal
              Btn={({ setShowModal }: any) => (
                <div key={index} className="w-full h-full relative">
                  <img
                    className="w-full h-full object-cover"
                    src={adjustImageSize(m.url)}
                    alt={`media${index + 3}`}
                    onClick={() => setShowModal(true)}

                    // className="w-1/3"
                  />
                  {index === 2 && (
                    <div className="absolute top-0 right-0 bottom-0 left-0 text-4xl  text-white font-bold bg-black bg-opacity-20 flex items-center justify-center">
                      +{media.length - 5}
                    </div>
                  )}
                </div>
              )}
            >
              <Slideshow list={media} defaultIndex={index + 2} />
            </FullPortalModal>
          ))}
        </div>
      </div>
    );
  } else {
    return <>...</>;
  }
};

export const Post = ({ post, isPostPage, nbrOfComments, setEditedPost }: any) => {
  const { currentUser } = useAuth();
  const { addToContact, chatHandler } = useChat();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  // const cleanContent = DOMPurify.sanitize(post.content);

  const [isDeleted, setIsDeleted] = useState(false);
  const [isNetwork, setIsNetwork] = useState(post.author.isNetwork);
  const [isLiked, setIsLiked] = useState(
    post.likesPerPost.some((like: any) => like.userId === currentUser?.id)
  );
  const [numberOfLikes, setNumberOfLikes] = useState(post.likesPerPost.length);

  const commenters = Array.from(
    new Set(post.comments.map((com: any) => `${com.author.firstName} ${com.author.lastName}`))
  );

  const toggleLike = async (postId: number) => {
    if (!currentUser?.id) {
      navigate('/login', { state: { redirectTo: location } });
    }

    try {
      const res = await toggleLikePostService(postId);
      setIsLiked(res.data.liked);
      if (res.data.liked) {
        setNumberOfLikes((prev: number) => prev + 1);
      } else {
        setNumberOfLikes((prev: number) => prev - 1);
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const deletePost = async (id: number) => {
    try {
      await deletePostService(id);
      setIsDeleted(true);
    } catch (error: any) {
      alert(error.message);
    }
  };

  const blockUser = async (memberId: number) => {
    try {
      if (currentUser?.id) {
        await blockUserService(memberId);
      } else {
        navigate('/login', { state: { redirectTo: location } });
      }

      setIsDeleted(true);
    } catch (error: any) {
      alert(error.message);
    }
  };

  const addContactHandler = async (member: any) => {
    try {
      if (currentUser?.id) {
        await addToContact(member.id);
        setIsNetwork(true);
      } else {
        navigate('/login', { state: { redirectTo: location } });
      }
    } catch (error) {
      alert(error);
    } finally {
    }
  };

  const chatButtonHandler = async (member: any) => {
    if (currentUser?.id) {
      await chatHandler(member.id, member.firstName || '');
    } else {
      navigate('/login', { state: { redirectTo: location } });
    }
  };

  const openEditPostModal = () => {
    setEditedPost(post);
    // setShowModal(true);
    // setModalType(type);
  };

  const openPost = (id: number) => {
    if (currentUser?.id) {
      if (isPostPage) return;
      window.open(`/square/post/${id}`, '_blank');
      // navigate(`/square/post/${id}`);
    } else {
      navigate('/login', { state: { redirectTo: location } });
    }
  };

  if (isDeleted)
    return (
      <div className="p-12 flex rounded-2xl items-center justify-center duration-1000 bg-gray-100 text-gray-400">
        {t('post deleted')}
      </div>
    );

  return (
    <div className="flex flex-col gap-y-1 text-[0.9375rem]">
      <HeaderUser
        user={post.author}
        createdAt={post.createdAt}
        isNetwork={isNetwork}
        isPostPage={isPostPage}
      >
        {post.author.id === currentUser?.id && (
          <>
            {isPostPage ? (
              <>
                <DropdownItem
                  itemLabel={t('Edit post')}
                  imageUrl="/images/icons/edit-post.svg"
                  onClick={() => openEditPostModal()}
                />
              </>
            ) : (
              <DropdownItem
                itemLabel={t('Edit post')}
                imageUrl="/images/icons/edit-post.svg"
                onClick={() => window.open(`square/post/${post.postId}`, '_blank')}
              />
            )}
            <DropdownItem
              itemLabel={t('Delete post')}
              imageUrl="/images/icons/delete-post.svg"
              onClick={() => deletePost(post.postId)}
            />
          </>
        )}

        {post.author.id !== currentUser?.id && !isNetwork && (
          <DropdownItem
            itemLabel={t('Add contact')}
            imageUrl="/images/icons/add-contact.svg"
            onClick={() => addContactHandler(post.author)}
          />
        )}
        {post.author.id !== currentUser?.id && isNetwork && (
          <DropdownItem
            itemLabel={t('Chat')}
            imageUrl="/images/icons/post-chat.svg"
            onClick={() => chatButtonHandler(post.author)}
          />
        )}
        {post.author.id !== currentUser?.id && (
          <DropdownItem
            itemLabel={t('Block member')}
            imageUrl="/images/icons/block-member.svg"
            onClick={() => blockUser(post.author.id)}
          />
        )}
      </HeaderUser>
      <div className=" flex flex-col gap-y-1">
        <div className="font-bold text-2xl pb-1 px-3">{post.title}</div>
        {/* <div>{post.content}</div> */}
        <div
          className={`pb-3 px-3 square-link quill-style ${
            post.type === POST_TYPES.ARTICLE && 'order-2'
          } `}
          dangerouslySetInnerHTML={{ __html: post.content }}
        ></div>
        {post.media && post.media.length > 0 && (
          <>
            {post.aboutPropertyId ? (
              <div
                title={t('Open Listing page')}
                onClick={() => window.open(`/properties/${post.aboutPropertyId}`, '_blank')}
                className="cursor-pointer filter hover:contrast-125 hover:opacity-90 duration-200 aspect-square w-full overflow-hidden flex items-center justify-center"
              >
                <img
                  alt="listing"
                  src={adjustImageSize(post.media[0].url)}
                  className="h-full w-full object-cover"
                />
              </div>
            ) : (
              <div className="cursor-pointer filter hover:contrast-125 hover:opacity-90 duration-200 aspect-square w-full overflow-hidden flex items-center justify-center">
                <MediaGallery media={post.media} />
              </div>
            )}
          </>
        )}
      </div>
      <div className="flex px-3 py-3">
        <div className=" w-full text-sm flex justify-between">
          <div className="flex">
            <div className="flex gap-x-1 w-36 items-center">
              <AiFillHeart
                onClick={() => {
                  toggleLike(post.postId);
                }}
                className={`w-4 h-4 sm:h-4 sm:w-4
                ${isLiked ? 'text-deehiy-red' : 'text-gray-300'}
                 duration-200 cursor-pointer`}
              />
              <div>{numberOfLikes}</div>
            </div>
            <div className="flex gap-x-1 items-center group relative">
              <BsChatDots
                onClick={() => openPost(post.postId)}
                className="w-4 h-4 sm:h-4 sm:w-4 text-defaultText cursor-pointer hover:text-black"
              />
              <div>{nbrOfComments || post.comments.length}</div>
              {/* tooltip */}

              {commenters && commenters.length > 0 && (
                <ul
                  className={`group-hover:visible invisible group-hover:w-48 w-0 delay-300 
                absolute top-6 border border-gray-200 text-white gap-y-1 bg-gray-600 px-3 py-1 flex flex-col rounded-xl shadow-lg z-10`}
                >
                  {commenters.map((com: any, i: number) => (
                    <li key={i}>{com}</li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="hidden bg-blue items-center justify-end">
            <BsFillShareFill className="w-4 h-4 sm:h-4 sm:w-4 text-defaultText z-10 cursor-pointer" />
          </div>
        </div>
      </div>
    </div>
  );
};

export const Feed = ({ posts, setEditedPost }: any) => {
  return (
    <div className="flex flex-col gap-y-2 sm:gap-y-4 overflow-hidden">
      {/* overflow-hidden */}
      {posts.map((post: any, i: number) => {
        return (
          <PostContainer key={i}>
            <Post post={post} isPostPage={false} setEditedPost={setEditedPost} />
          </PostContainer>
        );
      })}
    </div>
  );
};

const Square = ({ posts, hasMore, handleSearch }: any) => {
  // const [showModal, setShowModal] = useState(true);
  return (
    <div>
      <InfiniteScroll
        dataLength={posts.length}
        next={handleSearch}
        hasMore={hasMore}
        loader={<div className="text-sm text-gray-500 text-center p-12"></div>}
        endMessage={<span></span>}
        className="w-full  mt-12 sm:mt-0 mx-0"
      >
        <Feed posts={posts} />
      </InfiniteScroll>
    </div>
  );
};

export default Square;
