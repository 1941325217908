import React from 'react';
import FilterSideButton from '../main/filter/FilterSideButton';
import Modal2 from '../listing/Modal2';

const OverlaySidebarLayout = ({
  Filter,
  children,
  isFilterOpen,
  setIsFilterOpen,
  ...Props
}: any) => {
  const isMobile = window.innerWidth <= 768;

  return (
    <div>
      <FilterSideButton setIsFilterOpen={setIsFilterOpen} isFilterOpen={isFilterOpen} />
      {isMobile ? (
        <Modal2 showModal={isFilterOpen} setShowModal={setIsFilterOpen}>
          <Filter setIsFilterOpen={setIsFilterOpen} {...Props} />
        </Modal2>
      ) : (
        <div
          className={`w-full z-10  fixed top-16 xl:w-[300px] lg:w-[270px] md:w-[220px] shadow-xl h-screen bg-white transition-transform duration-1000 ease-in-out ${
            isFilterOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
        >
          <Filter setIsFilterOpen={setIsFilterOpen} {...Props} />
        </div>
      )}
      <div className="width-overlay-layout mx-auto">{children}</div>
    </div>
  );
};

export default OverlaySidebarLayout;
