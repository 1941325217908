import React, { useState, useEffect } from 'react';
import HelpCenterSearch from '../../components/helpCenter/HelpCenterSearch';
import { useNavigate, useLocation } from 'react-router-dom';
import ArticleSlideShow from '../../components/helpCenter/ArticleSlideShow';
import { ArticleContent } from '../../types/types';
import SEO from '../../components/SEO/SEO';
import { getAllArticles } from '../../services/articleService';
import Loader from '../../components/main/Loader';
import { useTranslation } from 'react-i18next';

export interface ArticlesCards {
  id: number;
  title: string;
  keywords: string;
  image: string;
  preview: string;
}

const HelpCenterHomePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [articlesCards, setArticlesCards] = useState<ArticlesCards[] | null>();
  // const [articles, setArticles] = useState<ArticleContent[]>([
  //   { id: 0, date: '', category: '', title: '', keywords: '', image: '', preview: '', text: '' },
  // ]);
  const [filteredArticlesCards, setFilteredArticlesCards] = useState<ArticlesCards[] | null>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getAllArticles().then((res: any) => {
      const cardsArray = res.data.map((art: ArticleContent) => ({
        id: art.id,
        title: art.title,
        preview: art.preview,
        keywords: art.keywords,
        image: art.image,
      }));

      setArticlesCards(cardsArray);
      setFilteredArticlesCards(cardsArray);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div className="px-6">
      <SEO
        title="Help Center - Deehiy"
        description="We are here to help"
        type="landing"
        name="Help Center"
        image="https://res.cloudinary.com/dpdnpljjb/image/upload/c_scale,q_100,w_1200/v1686028841/help-center/getting-started.png"
      />
      {/* big title not on mobile */}
      <div className="w-full hidden sm:flex justify-center text-3xl sm:text-5xl font-bold pt-0 sm:pt-8 pb-4 sm:py-6">
        {t('Hi, how can we help?')}{' '}
      </div>
      {/* search */}
      <div className="w-full sm:w-1/3 mx-auto">
        <HelpCenterSearch
          articlesCards={articlesCards}
          setFilteredArticlesCards={setFilteredArticlesCards}
        />
      </div>
      {/* encadre */}

      <div className="flex p-3 sm:p-6 my-8 sm:my-16 flex-col w-full mx-auto sm:w-4/5 sm:flex-row sm:justify-between bg-white border rounded-2xl border-deehiy ">
        <div className="flex flex-col w-full sm:w-1/2">
          <span className="text-2xl font-bold">{t('We’re here to help')}</span>
          <span className="text-sm">{t('Log in to enjoy a better experience and support.')}</span>
        </div>

        <div className="mx-auto flex w-full sm:w-1/2 justify-center sm:justify-end">
          <button
            onClick={() => navigate('/login', { state: { redirectTo: location } })}
            className="bg-deehiy  flex rounded-xl items-center px-6 py-2 mt-3 sm:mt-0 sm:px-24 text-white hover:opacity-80 cursor-pointer font-bold"
          >
            {t('log in or sign up')}
          </button>{' '}
        </div>
      </div>

      {/* slideshow */}
      <div className="text-xl font-bold">{t('Guides to get most of Deehiy')}</div>
      <div className="text-gray-400 opacity-90 text-sm">
        {filteredArticlesCards?.length} {t(`article(s)`)}
      </div>
      <div className="">
        {filteredArticlesCards && filteredArticlesCards.length > 0 ? (
          <ArticleSlideShow list={filteredArticlesCards} />
        ) : (
          <span>{t('no article found')}</span>
        )}
      </div>
    </div>
  );
};

export default HelpCenterHomePage;
