import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { Business, BusinessContextType, Property, User } from '../types/types';
import {
  SET_BUSINESS,
  SET_BUSINESS_PROPERTIES,
  SET_BUSINESS_USERS,
  SET_CURRENT_BUSINESS,
} from '../actions/actions';
import { businessReducer } from '../reducers/businessReducer';
import {
  createBusinessService,
  getBusinessByUserIdService,
  updateBusinessService,
  getBusinessByIdService,
  getUsersByBusinessIdService,
  getPropertiesByBusinessIdService,
} from '../services/businessService';
import {
  initialBusiness,
  initialBusinessProperty,
  initialBusinessUser,
} from '../initialStates/initialStates';
import { useAuth } from './auth';
// import { useInternationalization } from './internationalization';

const initialBusinessContext = {
  business: initialBusiness,
  currentBusiness: initialBusiness,
  properties: [initialBusinessProperty],
  users: [initialBusinessUser],
  setProperties: () => {},
  setUsers: () => {},
  setBusinessContext: () => {},
  fetchBusinessByIdContext: async () => {},
  fetchBusinessByUserIdContext: async () => {},
  updateBusinessContext: async () => {},
  createBusinessContext: async () => '',
  fetchPropertiesByBusinessIdContext: async () => {},
  fetchUsersByBusinessIdContext: async () => {},
};

export const BusinessContext = createContext<BusinessContextType>(initialBusinessContext);

export const BusinessProvider = ({ children }: any) => {
  const [{ business, currentBusiness, users, properties }, dispatch] = useReducer(
    businessReducer,
    initialBusinessContext
  );
  // const { currency } = useInternationalization();
  const { currentUser } = useAuth();

  // set current business
  const setBusinessContext = (updatedBusiness: Business) => {
    dispatch({ type: SET_BUSINESS, payload: updatedBusiness });
  };

  const setProperties = (updatedProperties: Property[]) => {
    dispatch({ type: SET_BUSINESS_PROPERTIES, payload: updatedProperties });
  };

  const setUsers = (updatedUsers: User[]) => {
    dispatch({ type: SET_BUSINESS_USERS, payload: updatedUsers });
  };

  const fetchUsersByBusinessIdContext = async (businessId: number) => {
    try {
      const fetchedUsers = await getUsersByBusinessIdService(businessId);

      console.log('fetchedUsers data::', fetchedUsers);

      setUsers(fetchedUsers.data);

      return fetchedUsers.data;
    } catch (err: any) {
      console.log(err);
      throw new Error(err.response.data);
    }
  };

  const fetchPropertiesByBusinessIdContext = async (businessId: number, curr: string) => {
    try {
      const fetchedProperties = await getPropertiesByBusinessIdService(businessId, curr);

      setProperties(fetchedProperties.data);

      return fetchedProperties.data;
    } catch (err: any) {
      console.log(err);
      throw new Error(err.response.data);
    }
  };

  //get Business By Id
  const fetchBusinessByIdContext = async (id: number) => {
    try {
      const fetchedBusiness = await getBusinessByIdService(id);

      return fetchedBusiness.data;
    } catch (err: any) {
      console.log(err);
      throw new Error(err.response.data);
    }
  };

  // create business
  const createBusinessContext = async (editedBusiness: Business) => {
    try {
      await createBusinessService({ ...editedBusiness, adminId: currentUser?.id });

      return 'success';
    } catch (err: any) {
      console.log(err);
      throw new Error(err.response.data);
    }
  };

  // update business
  const updateBusinessContext = async (editedParams: any) => {
    try {
      const updatedBusiness = await updateBusinessService({
        ...editedParams,
        businessId: currentBusiness.businessId,
      });

      dispatch({ type: SET_CURRENT_BUSINESS, payload: updatedBusiness.data });

      return updatedBusiness;
    } catch (err: any) {
      console.log(err);
      throw new Error(err.response.data);
    }
  };

  const fetchBusinessByUserIdContext = async () => {
    try {
      const fetchedBusiness = await getBusinessByUserIdService(currentUser?.id);

      if (fetchedBusiness.status === 204) {
        console.log('no business found');
      } else {
        dispatch({ type: SET_CURRENT_BUSINESS, payload: fetchedBusiness.data.business });
      }
    } catch (err: any) {
      console.log(err);
      throw new Error(err.response.data);
    }
  };

  const value = {
    business,
    currentBusiness,
    users,
    properties,
    setProperties,
    setUsers,
    setBusinessContext,
    fetchBusinessByUserIdContext,
    createBusinessContext,
    updateBusinessContext,
    fetchBusinessByIdContext,
    fetchUsersByBusinessIdContext,
    fetchPropertiesByBusinessIdContext,
  };

  // fetch business details
  useEffect(() => {
    if (currentUser?.id) {
      fetchBusinessByUserIdContext().catch((err) => {
        alert(err.message);
      });
    }
  }, [currentUser?.id]);

  // fetch users
  useEffect(() => {
    if (currentBusiness.businessId) {
      fetchUsersByBusinessIdContext(currentBusiness.businessId).catch((err) => {
        alert(err.message);
      });
    }
  }, [currentBusiness.businessId]);

  return <BusinessContext.Provider value={value}>{children}</BusinessContext.Provider>;
};

export function useBusiness() {
  const context = useContext(BusinessContext);

  return context;
}
