import React, { useRef } from 'react';
import PropertyCard from '../PropertyCard';
import { useProperty } from '../../../contexts/property';
import { useTranslation } from 'react-i18next';
import { useGlobal } from '../../../contexts/global';
import InfiniteScroll from 'react-infinite-scroll-component';
import PromotedCard from '../PromotedCard';
import Loader from '../Loader';
import uuid from 'react-uuid';

const PropertiesGrid: React.FC<any> = () => {
  const { debouncedFetchProperties, pageIndex, properties, filter, hasMore } = useProperty();
  const { t } = useTranslation();
  const { isWL } = useGlobal();

  const listRef = useRef<HTMLDivElement>(null);

  if (!properties || properties.length === 0)
    return (
      <div className="text-center p-12 text-sm text-gray-500">
        {t('Looks like we ran out of options. Try changing your criteria.')}
      </div>
    );

  return (
    <div ref={listRef}>
      <InfiniteScroll
        dataLength={properties.length}
        next={() => debouncedFetchProperties(filter, pageIndex, properties)}
        hasMore={hasMore}
        loader={
          <div className="fixed bottom-36 inset-x-0">
            <Loader />
          </div>
        }
        endMessage={<div className="block text-gray-400 pt-12"></div>}
        className="flex flex-wrap xl:gap-4 md:gap-3 px-0 place-content-center sm:px-0 py-4 pb-12"
        style={{ overflow: 'hidden' }}
      >
        {properties?.map((item: any) => {
          if (isWL && (item?.type === 'promotedCard' || item?.type === 'promotedListing')) {
            return null; // Skip the item for WL
          }

          if (item?.type && item?.type === 'promotedCard') {
            return <PromotedCard card={item} />;
          } else if (item?.type && item?.type === 'promotedListing') {
            return <PropertyCard key={uuid()} property={item} isAd={true} />;
          } else {
            return <PropertyCard key={item.propertyId} property={item} />;
          }
        })}
      </InfiniteScroll>
    </div>
  );
};

export default PropertiesGrid;
