import { FunctionComponent } from 'react';
interface IProps {
  pointCount: number;
  onClick?: () => void;
  lat?: number;
  lng?: number;
}

const ClusterMarker: FunctionComponent<IProps> = ({ pointCount, onClick }: IProps) => {
  return (
    <span
      className="z-14 bg-deehiy text-sm font-bold text-white justify-center items-center w-8 h-8 flex rounded-full"
      onClick={onClick}
    >
      <span>{pointCount}</span>
    </span>
  );
};
export default ClusterMarker;
