import * as yup from 'yup';
import { AGENCY, CONSULTANCY, DEVELOPER } from '../constants/constant';
import {
  LatLong,
  LoginData,
  RegisterData,
  UpdatePasswordData,
  ForgotPasswordData,
  LocationFormData,
  SpecificsFormData,
  PropertyDescriptionFormData,
  PropertyDetailsFormData,
  UploadPropertyImagesFormData,
  HuntFormData,
  PropertyType,
  BusinessCategory,
  BusinessStep1FormData,
  BusinessStep2FormData,
  BusinessStep3FormData,
} from '../types/types';

// @ts-ignore - override correct yup type
// https://github.com/jquense/yup/issues/1183
export const requiredDateSchema: yup.SchemaOf<Date> = yup.date().required('Birth date is required');

export const loginSchema: yup.SchemaOf<LoginData> = yup.object().shape({
  email: yup.string().email('Need to be a valid email').required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
});

export const registerSchema: yup.SchemaOf<RegisterData> = yup.object().shape({
  // username: yup
  //   .string()
  //   .required('Username is required')
  //   .min(5, 'Username must be at least 5 characters long'),
  firstName: yup
    .string()
    .required('First name is required')
    .min(3, 'First name must be at least 3 characters long'),
  lastName: yup
    .string()
    .required('Last name is required')
    .min(3, 'Last name must be at least 3 characters long'),
  email: yup.string().email('Need to be a valid email').required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Must contain 8 Characters, 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Case Character'
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
});

export const forgotPasswordSchema: yup.SchemaOf<ForgotPasswordData> = yup.object().shape({
  email: yup.string().email('Need to be a valid email').required('Email is required'),
});

export const updatePasswordSchema: yup.SchemaOf<UpdatePasswordData> = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
});

export const locationFormSchema: yup.SchemaOf<LocationFormData> = yup.object().shape({
  streetNameHouseNumber: yup
    .string()
    .min(3, 'the input is too short')
    .required('Street name and house number are required'),
  city: yup.string().required('City is required'),
  postalCode: yup.string().required('Postal code is required').max(10, 'Postal code too long'),
});

export const specificsFormSchema: yup.SchemaOf<SpecificsFormData> = yup.object().shape({
  listingPrice: yup.number().required('Listing price is required').typeError('must be a `number`'),
  bedrooms: yup.number().required('Number of bedrooms is required').typeError('must be a `number`'),
  bathrooms: yup
    .number()
    .required('Number of bathrooms is required')
    .typeError('must be a `number`'),
  homeSize: yup.number().required('Home size is required').typeError('must be a `number`'),
  lotSize: yup.number().required('Lot size is required').typeError('must be a `number`'),
});

export const propertyDescriptionFormSchema: yup.SchemaOf<PropertyDescriptionFormData> = yup
  .object()
  .shape({
    propertyTitle: yup
      .string()
      .required('Property title is required')
      .max(50, 'the limit is 50 characters'),
    propertyDescription: yup
      .string()
      .required('Description title is required')
      .max(2000, 'the limit is 2000 characters'),
  });

export const propertyDetailsFormSchema: yup.SchemaOf<PropertyDetailsFormData> = yup.object().shape({
  features: yup.string().required('Features and Amenities are required'),
  floorLevel: yup.number().typeError('must be a `number`'),
  ceilingHeight: yup.number().typeError('must be a `number`'),
  parkingLots: yup.number().typeError('must be a `number`'),
  builtInDate: yup.string(),
  lastRenovationDate: yup.string(),
});

// @ts-ignore - file type still not found
// TODO Should be ArraySchema<ObjectSchemaOf<FileWithPreview> | Lazy<ObjectSchemaOf<FileWithPreview>, any>, AnyObject
export const uploadPropertyImagesFormSchema: yup.SchemaOf<UploadPropertyImagesFormData> = yup
  .object()
  .shape({
    property_images: yup.mixed().required('File is required'),
    // floorplans: yup.mixed().required("File is required"),
  });

export const mapSchema: yup.SchemaOf<LatLong> = yup.object().shape({
  lat: yup.number().typeError('Must be a number').required('Lat is required'),
  lng: yup.number().typeError('Must be a number').required('Long is required'),
});

export const huntFormSchema: yup.SchemaOf<HuntFormData> = yup.object().shape({
  location: yup.string().required(),
  areaRadius: yup.number().required().typeError('must be a `number`'),
  propertyType: yup
    .mixed<PropertyType>()
    .oneOf(['Land', 'Townhouse', 'House / Villa', 'Condo / Apartment', 'all'] as PropertyType[])
    .required(),
  homeSize: yup.number().required().typeError('must be a `number`'),
  lotSize: yup.number().required().typeError('must be a `number`'),
  bedrooms: yup.number().required().typeError('must be a `number`'),
  price: yup.number().required(),
});

export const businessStep1FormSchema: yup.SchemaOf<BusinessStep1FormData> = yup.object().shape({
  businessName: yup.string().required(),
  businessCategory: yup
    .mixed<BusinessCategory>()
    .oneOf([AGENCY, DEVELOPER, CONSULTANCY] as BusinessCategory[])
    .required('Business category is required'),
});

export const businessStep2FormSchema: yup.SchemaOf<BusinessStep2FormData> = yup.object().shape({
  streetNameHouseNumber: yup.string().required(),
});

export const businessStep3FormSchema: yup.SchemaOf<BusinessStep3FormData> = yup.object().shape({
  businessEmail: yup.string().email('Need to be a valid email').required('Email is required'),
});
