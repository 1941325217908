import React, { useState } from 'react';
import PropertyCard from '../main/PropertyCard';
import { adjustImageSize } from '../../utils/adjustImageSize';

const CircularMarker = ({ place, lat, lng, setCenter }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMarkerClick = () => {
    setIsOpen(!isOpen);
    setCenter({ lat, lng });
  };

  return (
    <div onClick={handleMarkerClick} className="group flex w-8 h-8 rounded-full bg-deehiy">
      {!place.images[0] ? (
        <div
          className="border-4 text-white justify-center items-center flex font-bold
         text-sm font-title border-deehiy w-8 h-8 rounded-full hover:scale-105 duration-300"
        >
          d
        </div>
      ) : (
        <img
          alt=""
          src={adjustImageSize(place.images[0].url, 50)}
          className="border-4 border-deehiy w-8 h-8 rounded-full hover:scale-105 duration-300"
        />
      )}
      {/* desktop */}
      <div className={` hidden mx-auto group-hover:block w-[250px] z-20`}>
        {/* <InfoWindow place={place} /> */}
        <PropertyCard property={place} />
      </div>
      {/* mobile */}
      <div
        className={`${
          isOpen ? 'relative' : 'hidden'
        } sm:hidden  flex flex-col w-[300px] ml-[-150px] mt-12 z-20`}
      >
        {/* <InfoWindow place={place} /> */}
        <div className="absolute  z-30 top-0 flex w-full p-2 justify-end ">
          <button className="px-2 rounded py-1 bg-white absolute " onClick={handleMarkerClick}>
            close X
          </button>
        </div>
        <PropertyCard property={place} />
      </div>
    </div>
  );
};

export default CircularMarker;
