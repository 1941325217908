import React from 'react';
import AdaptiveSidebarLayout from '../../components/square/AdaptiveSidebarLayout';
import Market from '../../components/main/market/Market';
import MarketFilter2 from '../../components/main/filter/MarketFilter2';
// import FeaturesCarousel from '../../components/main/carousel/FeaturesCarousel';
import SEO from '../../components/SEO/SEO';
// import NewLook from '../../components/intro/video/NewLook';
// import Modal from '../../components/listing/Modal';

const MarketPage = () => {
  // const introViewed = localStorage.getItem('introViewed');

  return (
    <>
      <SEO
        title="Buy & Sell With Ease - Deehiy"
        description="Buy and sell property with ease globally and take control of your real estate business"
        name="Deehiy"
        type="home page"
        image={`${process.env.REACT_APP_BASE_URL_PROD}/images/meta-image.jpeg`}
      />
      {/* intro modal */}
      {/* {introViewed !== process.env.REACT_APP_VIDEO_INTRO_ID && (
        <Modal absoluteCloseBtn={true}>
          <NewLook />
        </Modal>
      )} */}
      {/* top filter carrousel */}
      {/* <div
        className="w-full top-2 sm:top-16 z-10 sm:px-20 pb-1 flex px-6 bg-[#f7f7f7] mx-auto sticky 
        shadow-md border border-transparent border-b-deehiy sm:border-none"
      >
        <FeaturesCarousel />
      </div> */}

      <AdaptiveSidebarLayout Filter={MarketFilter2}>
        <Market />
      </AdaptiveSidebarLayout>
    </>
  );
};

export default MarketPage;
