import React, { useState, useEffect } from 'react';
import {
  getMatchesByUserIdService,
  submitAllMatchesService,
} from '../../../../services/huntService';
import Loader from '../../../main/Loader';
import { useAuth } from '../../../../contexts/auth';
import { useNavigate } from 'react-router-dom';
import LeadTable from '../table/LeadTable';
import { Match } from '../../../../types/types';
import { useTranslation } from 'react-i18next';

const ListingLeadsContent = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [matches, setMatches] = useState<Match[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSubmitAllBtn, setShowSubmitAllBtn] = useState(true);
  const [numberOfNewMatches, setNumberOfNewMatches] = useState(0);

  useEffect(() => {
    setIsLoading(true);

    getMatchesByUserIdService(currentUser?.id)
      .then((fetched) => {
        console.log(fetched.data);
        console.log(matches);
        setMatches(fetched.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentUser]);

  const handleSubmitAll = () => {
    setIsLoading(true);
    submitAllMatchesService(currentUser?.id)
      .then(() => {
        navigate('/users/leadsubmitted');
      })
      .catch((err: any) => {
        alert(`something went wrong:${err}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (matches.length > 0) {
      const toSubmit = matches.filter((match: Match) => match.isSubmitted !== true);

      if (toSubmit.length > 0) {
        setShowSubmitAllBtn(true);
        setNumberOfNewMatches(toSubmit.length);
      } else {
        setShowSubmitAllBtn(false);
      }
    }
  }, [matches]);

  if (matches.length === 0) return <>{t('no leads found')}</>;

  return (
    <div className="w-full">
      {isLoading && <Loader />}
      <div className="font-bold flex justify-between my-4 items-center">
        <span>{t('new_leads_message', { count: numberOfNewMatches })}</span>
        {showSubmitAllBtn && (
          <div>
            <button
              onClick={handleSubmitAll}
              // className="mr-4 rounded-full px-2 py-1 sm:px-4 sm:py-2 text-xs sm:text-md bg-white border border-deehiy text-deehiy font-bold hover:opacity-90 shadow-md duration-200 whitespace-nowrap"
              className="secondary-btn"
            >
              {t('Submit all')}
            </button>
          </div>
        )}
      </div>

      {/* <table
        style={{ border: '1px solid green' }}
        className=" w-full bg-white border ring-1 ring-deehiy shadow-lg  border-deehiy rounded-lg overflow-hidden "
      >
        <thead className="font-bold border shadow-sm border-transparent border-b-deehiy">
          <th className={th}>Listing</th>
          <th></th>
          <th className={th}>Location</th>
          <th>Match rate</th>
          <th></th>
        </thead>
        <tbody className="font-normal">
          {matches &&
            matches.map((match: any) => {
              return (
                <tr className="text-sm border  border-y-deehiy last:border-l-transparent h-28">
                  <th>
                    <img
                      src={
                        match.property?.images[0]
                          ? adjustImageSize(match.property.images[0]?.url, 300)
                          : process.env.REACT_APP_DEFAULT_LISTING_URL
                      }
                      alt=""
                      className="p-3 pr-0 h-28 aspect-video"
                    />
                  </th>
                  <th
                    title="open listing page"
                    className="font-bold text-left px-4 cursor-pointer hover:underline"
                    onClick={() => window.open(`/properties/${match.propertyId}`, '_blank')}
                  >
                    {match.property?.propertyTitle}
                  </th>
                  <th className="text-left font-normal">{match.property?.city}</th>
                  <th className="text-center font-normal">{match.percent}%</th>
                  <th>
                    <button
                      title="submit this lead"
                      onClick={() => handleSubmit(match.id)}
                      type="button"
                      className="mx-4 rounded-full duration-300 hover:shadow-md cursor-pointer border border-deehiy py-2 px-6 bg-white text-deehiy shadow-sm"
                    >
                      Submit
                    </button>
                  </th>
                </tr>
              );
            })}
        </tbody>
      </table> */}
      <LeadTable />
    </div>
  );
};

export default ListingLeadsContent;
