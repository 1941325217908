import React from 'react';
import { interestsList } from '../../../data/data';
import { POST_TYPES } from '../../../constants/constant';
import FilterCheckbox from './FilterCheckbox';
// import FilterDropdown from './FilterDropdown';
// import FilterList from './FilterList';
import FilterContainer from './FilterContainer';
import FilterDropdown from './FilterDropdown';
import FilterSearchBtn from './FilterSearchBtn';
import { useTranslation } from 'react-i18next';

type PostType = (typeof POST_TYPES)[keyof typeof POST_TYPES];

const SquareFilter = React.memo(
  ({
    tempFilter,
    setTempFilter,
    setIsFilterOpen,
    filterState,
    setFilterState,
    resetFilter,
    handleSearch,
    count,
  }: any) => {
    const { t } = useTranslation();

    // const [fromNetwork, setFromNetwork] = useState(false);
    // const [includeAds, setIncludeAds] = useState(false);
    // const [selectedTypes, setSelectedTypes] = useState<PostType[]>(searchParams.selectedTypes);
    // const [showOptions, setShowOptions] = useState(false);
    // const [showInterests, setShowInterests] = useState(false);

    const handleTypeCheckboxChange = (type: PostType) => {
      let selectedTypes = tempFilter.selectedTypes;

      if (selectedTypes.includes(type)) {
        selectedTypes = selectedTypes.filter((selectedType: any) => selectedType !== type);
      } else {
        selectedTypes = [...selectedTypes, type];
      }
      setTempFilter((prev: any) => ({ ...prev, selectedTypes: selectedTypes }));
    };

    const handleFromNetworkChange = () => {
      setTempFilter((prev: any) => ({ ...prev, fromNetwork: !tempFilter.fromNetwork }));
    };

    const handleInterestsCheckboxChange = (interest: string) => {
      let interests = tempFilter.interests;

      if (interests.includes(interest)) {
        interests = interests.filter((selectedInterest: any) => selectedInterest !== interest);
      } else {
        interests = [...interests, interest];
      }

      setTempFilter((prev: any) => ({ ...prev, interests: interests }));
    };

    return (
      <FilterContainer setIsFilterOpen={setIsFilterOpen} title="Post filters">
        <div className="sm:relative h-[calc(100vh-7rem)]">
          <div className="scroller relative">
            <div className="flex flex-col">
              <FilterCheckbox
                label={t('Posts')}
                isChecked={tempFilter.selectedTypes.includes(POST_TYPES.GENERAL)}
                check={() => handleTypeCheckboxChange(POST_TYPES.GENERAL)}
              />
              <FilterCheckbox
                label={t('Discussions')}
                isChecked={tempFilter.selectedTypes.includes(POST_TYPES.DISCUSSION)}
                check={() => handleTypeCheckboxChange(POST_TYPES.DISCUSSION)}
              />
              <FilterCheckbox
                label={t('Articles')}
                isChecked={tempFilter.selectedTypes.includes(POST_TYPES.ARTICLE)}
                check={() => handleTypeCheckboxChange(POST_TYPES.ARTICLE)}
              />
            </div>
            <FilterCheckbox
              label={t('From your connections')}
              isChecked={tempFilter.fromNetwork}
              check={handleFromNetworkChange}
            />
            <FilterDropdown
              sectionHeight={450}
              label={t('Interests')}
              isOpen={filterState.isInterestsOpen}
              setIsOpen={() =>
                setFilterState({
                  ...filterState,
                  isInterestsOpen: !filterState.isInterestsOpen,
                })
              }
            >
              {interestsList.map((interest) => (
                <FilterCheckbox
                  key={interest.title}
                  title={t(interest.title)}
                  label={t(interest.title)}
                  Icon={interest.SVG}
                  isChecked={tempFilter.interests.includes(interest.title)}
                  check={() => handleInterestsCheckboxChange(interest.title)}
                />
              ))}
              {/* <FilterList list={interestsList} setSearchParams={setSearchParams} /> */}
            </FilterDropdown>
          </div>
          <div className=" w-full absolute  bottom-0 sm:bottom-8 flex flex-grow border  bg-white border-transparent border-t-gray-100 pt-2 pb-8">
            <FilterSearchBtn
              resetFilter={resetFilter}
              handleSearch={handleSearch}
              count={count}
              setIsFilterOpen={setIsFilterOpen}
            />
          </div>
        </div>
      </FilterContainer>
    );
  }
);

export default SquareFilter;
