import React, { useEffect, useState, useRef } from 'react';
import { FaSearch } from 'react-icons/fa';
// import { useProperty } from '../../contexts/property';
import { useGlobal } from '../../contexts/global';
import { useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

const NavSearch = ({ search, setSearch }: any) => {
  const { keyword, setKeyword } = useGlobal();
  const [searched, setSearched] = useState('');
  // const { setCoordinates } = useProperty();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const path = location.pathname;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    // trigger  search with filter (square or community or market)
    setKeyword(searched);

    // used for responsive search (mobile)
    if (setSearch) {
      setSearch(keyword);
    }

    // redirect to all results page if not on the main urls
    console.log('PATH', path);
    // if (path !== '/community' && path !== '/square' && path !== '/') {
    //   navigate('/all');
    // }
    navigate('/all');

    // get lat long and set coordinates
    // const response = await fetch(
    //   `https://maps.googleapis.com/maps/api/geocode/json?address=${searched}&key=${process.env.REACT_APP_GOOGLEMAP_WEBSERVICE_API_KEY}`
    // );
    // const data = await response.json();
    // if (!data.results.length) {
    //   if (setMessage) {
    //     setMessage('no result found');
    //     return;
    //   }
    // }
    // const loc = data.results[0].geometry.location;
    // // setFormattedAddress(data.results[0].formatted_address)
    // setCoordinates({ lat: loc.lat, lng: loc.lng });
  };

  const handleChange = (e: any) => {
    setSearched(e.target.value);
  };

  const reset = () => {
    inputRef.current!.focus();
    setSearched('');
    setSearch('');
    setSearch('');
  };

  useEffect(() => {
    setSearched(search);
  }, [search]);

  return (
    <form onSubmit={handleSubmit} className="m-0">
      <div className="flex h-9 mx-6 font-bold relative ">
        <button
          title={t('search')}
          className={
            'bg-grayBg text-placeholderText active:textdefaultText group py-2 px-2 sm:py-2 sm:pl-[10px] rounded-l-full'
          }
          type="submit"
        >
          <FaSearch className="w-4 h-4 text-placeholderText group-active:text-defaultText group-hover:rotate-90 duration-300 " />
        </button>
        <input
          ref={inputRef}
          className={
            'outline-0 bg-grayBg placeholder-placeholderText  hover:bg-gray-100 focus:bg-gray-100 border-none  text-xs ring-0 rounded-r-full focus:ring-0 w-full'
          }
          type="text"
          value={searched}
          placeholder={t('Search Deehiy')}
          onChange={handleChange}
        />
        {searched && (
          <div className="absolute h-full right-2 flex items-center">
            <button
              onClick={reset} // Clear the input field
              type="reset"
              className=" flex items-center "
            >
              <AiOutlineClose className="w-3 h-3 text-gray-500" />
            </button>
          </div>
        )}
      </div>
    </form>
  );
};

export default NavSearch;
