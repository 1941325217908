import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { MdOutlineArrowForwardIos, MdOutlineArrowBackIos } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { ArticlesCards } from '../../pages/info/HelpCenterHomePage';

const buttonStyle =
  'hidden md:flex w-18 h-18 p-2 text-2xl bg-gray-400 text-white rounded-full opacity-50 hover:opacity-70 hover:bg-white hover:text-gray-300 duration-300';

const properties = {
  prevArrow: (
    <button className={buttonStyle}>
      <MdOutlineArrowBackIos />
    </button>
  ),
  nextArrow: (
    <button className={buttonStyle}>
      <MdOutlineArrowForwardIos w-26 />
    </button>
  ),
};

const ArticleCard = ({ article: { title, preview, id } }: any) => {
  const navigate = useNavigate();

  return (
    <div
      className="hover:scale-100 sm:scale-95  duration-500 cursor-pointer flex flex-col"
      onClick={() => navigate(`/help/article/${id}`)}
    >
      <img
        src={preview}
        alt="article"
        className="rounded-2xl shadow-sm max-w-[280px] object-cover aspect-video sm:aspect-square"
      />
      <span className="font-bold text-md px-2 pt-2">{title}</span>
    </div>
  );
};

const ArticleSlideShow = ({ list }: any) => {
  return (
    <section className="mx-auto p-2 max-w-[1200px]">
      <Slide
        {...properties}
        infinite={false}
        slidesToScroll={1}
        slidesToShow={1} // potential bug if using number != slidesToScroll
        indicators={false}
        arrows={list.length > 4}
        canSwipe={true}
        autoplay={false}
        responsive={[
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ]}
      >
        {list.map((art: ArticlesCards) => {
          return <ArticleCard key={art.title} article={art} />;
        })}
      </Slide>
    </section>
  );
};

export default ArticleSlideShow;
