import React, { useState } from 'react';
import Footer from './Footer';
// import BackToTopButton from '../main/BackToTopButton';
import { Navigation } from '../main/navigation/Navigation';
import { Outlet } from 'react-router-dom';
import MobileNavigation from '../main/navigation/MobileNavigation';
import NavSearch from '../listingMap/NavSearch';
import NotificationBanner from '../main/NotificationBanner';
import { MobileTopNavigation } from '../main/navigation/MobileTopNavigation';
import DownloadAppPopup from '../main/DownloadAppPopup';
// const links = [
//   { to: '/addproperty', label: 'List Property' },
//   { to: '/huntingboard', label: 'Hunt Property' },
// ];

const Layout = ({ children }: any) => {
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  return (
    <div className="dark:bg-slate-900 flex flex-col min-h-screen h-screen ">
      <header>
        <div className="hidden sm:block">
          {/* <Navigation Search={NavSearch} links={links} /> */}
          <Navigation Search={NavSearch} />
        </div>
        <div className="block sm:hidden">
          <NotificationBanner
            isBannerVisible={isBannerVisible}
            setIsBannerVisible={setIsBannerVisible}
          />

          <MobileTopNavigation isBannerVisible={isBannerVisible} />
        </div>
        <div className="block sm:hidden">
          <MobileNavigation />
        </div>
      </header>
      {/* <main className="mx-auto pt-0 sm:pt-14 flex flex-col flex-grow w-full"> */}

      <main
        className={`${
          isBannerVisible ? 'pt-14' : 'pt-0'
        } sm:pt-14 mx-auto bg-white sm:bg-auth-bg  flex flex-col flex-grow w-full`}
      >
        <Outlet />
        {children}
      </main>
      {/* <BackToTopButton /> */}
      <DownloadAppPopup />
      <Footer />
    </div>
  );
};

export default Layout;
