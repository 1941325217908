import React from 'react';
// import { ThumbUpIcon } from "@heroicons/react/24/outline"
import { AiFillHeart } from 'react-icons/ai';
import { BsDot } from 'react-icons/bs';
import { NumericFormat } from 'react-number-format';
import { nFormatter } from '../../utils/numberFormatter';
// import { timeAgo } from '../../utils/dateFormatter';
import { Property } from '../../types/types';
import { LAND } from '../../constants/constant';
import { useNavigate } from 'react-router-dom';
import { adjustImageSize } from '../../utils/adjustImageSize';
import { useProperty } from '../../contexts/property';
import { useGlobal } from '../../contexts/global';

import { useTranslation } from 'react-i18next';
import { getCountryAreaUnit } from '../dashboard/tabsContents/ListingBasicContent';

interface IPropCard {
  property: Property;
  isAd?: boolean;
}
// TODO: optmize images to show 3 smaller images on mobile, see commented code line 60
const PropertyCard = ({ property, isAd }: IPropCard) => {
  const { t } = useTranslation();

  const {
    propertyId,
    city,
    homeSize,
    lotSize,
    listingPrice,
    monthlyRentPrice,
    bedrooms,
    bathrooms,
    images,
    viewCount,
    propertyType,
    isoCodeCurrency,
    isoCodeCountry,
    hasSellerFinance,
    isForRent,
    isForSale,
    isOffPlan,
    isPOR,
    businessId,
  } = property;
  const navigate = useNavigate();
  const { filter } = useProperty();
  const { isWL } = useGlobal();
  const areaUnit = getCountryAreaUnit(isoCodeCountry);

  const handleClick = () => {
    // Determine the path based on whether it's a white-label
    const pathPrefix = isWL ? `/business/${businessId}` : '';

    // Construct the full URL based on device type and path
    const fullURL = `${pathPrefix}/properties/${propertyId}`;

    if (window.innerWidth <= 768) {
      // Handle click behavior for mobile devices
      navigate(fullURL);
    } else {
      // Open new tab for desktop devices
      window.open(fullURL, '_blank');
    }
  };

  return (
    <div
      onClick={handleClick}
      // className=" group max-h-[400px] min-w-[210px]  sm:max-h-[330px] rounded-2xl sm:border sm:shadow-md hover:shadow-xl bg-[#f7f7f7] sm:bg-white border-deehiy cursor-pointer transition duration-500 ease-in transform sm:hover:scale-105 hover:z-0 mb-4 sm:mb-0"
      // className="group max-h-[40vh] min-w-[16vw] sm:max-h-[36vh] rounded-2xl sm:border sm:shadow-md hover:shadow-xl bg-[#f7f7f7] sm:bg-white border-deehiy cursor-pointer transition duration-500 ease-in transform sm:hover:scale-105 hover:z-0 mb-4 sm:mb-0"
      className="market-card group px-2 sm:px-0 mt-1 sm:mt-0 pt-2 sm:pt-0 mb-2 sm:mb-0 bg-[#f7f7f7] sm:bg-white"
    >
      <div className="overflow-hidden  aspect-video rounded-2xl sm:rounded-none sm:rounded-t-2xl relative group">
        <AiFillHeart className="absolute w-4 h-4 sm:h-5 sm:w-5 mt-2 mx-2 right-1 group-hover text-white z-10 hover:text-red-400 duration-200" />
        {isAd && (
          <div className="absolute semibold bottom-2 left-2 bg-deehiy py-1  z-10 text-gray-600 bg-opacity-70 rounded-full bg-gray-200 px-2 text-xs">
            Promoted
          </div>
        )}
        <div className="absolute flex mt-2 mx-2 font-bold gap-x-1 text-sm  z-10">
          {isForSale && !isForRent && (
            <span className="bg-white text-defaultText rounded-full px-1 bg-opacity-70">
              {t('Sale')}
            </span>
          )}
          {isForRent && !isForSale && (
            <span className="bg-white text-defaultText rounded-full px-1 bg-opacity-70">
              {t('Rent')}
            </span>
          )}
          {isForRent && isForSale && (
            <span className="bg-white text-defaultText rounded-full px-1 bg-opacity-70">
              {t('Sale & Rent')}
            </span>
          )}
          {isOffPlan && (
            <span className="bg-white text-defaultText rounded-full px-1 bg-opacity-70">
              {t('Off-plan')}
            </span>
          )}
        </div>
        <img
          className="object-cover w-full aspect-video group-hover:scale-105 transition duration-500 ease-in-out"
          src={images && adjustImageSize(images[0]?.url, 400)}
          alt="property"
          loading="lazy"
        />
        {hasSellerFinance && (
          <div className="absolute text-xs semibold rounded-full bottom-2 right-2 py-1 bg-deehiy text-white px-2">
            {t('Seller Finance')}
          </div>
        )}
      </div>
      {/* <div className="flex">
        <img
          className=" border rounded-bl-2xl object-cover w-1/3 h-20 block sm:hidden"
          src={images && images[1]?.url}
          alt=""
        />
        <img
          className="border  object-cover w-1/3 h-20 block sm:hidden"
          src={images && images[2]?.url}
          alt=""
        />
        <img
          className="border rounded-br-2xl object-cover w-1/3 h-20 block sm:hidden"
          src={images && images[3]?.url}
          alt=""
        />
      </div> */}
      <div className="px-2 sm:px-3 p-2 md:p-4  sm:gap-y-1 flex flex-col w-full">
        <div className="flex text-defaultText gap-x-1 text-xs font-bold items-center">
          <img alt="pin" src="/images/icons/pin-card.svg" className="w-4 h-4" />
          <span className="line-clamp-1" title={city}>
            {city}
          </span>
        </div>
        {propertyType !== LAND ? (
          <div className="opacity-60 group-hover:opacity-80 w-full">
            <div className="flex justify-between text-xs sm:text-xs w-full">
              <div className="flex items-center ">
                <span>{bedrooms}</span>
                <span className="mx-1">{t('rooms')}</span>
              </div>
              <BsDot className="h-6 mx-2" />
              <div className="flex items-center">
                {/* <BiBath className='h-6 mx-2' /> 2 */}
                <span>{bathrooms}</span>
                <span className="h-6 mx-1 items-center flex">{t('baths')}</span>
              </div>
              <BsDot className="h-6 mx-2" />
              <div className="flex w-[100px] items-center">
                {/* <BiArea className='h-6 mx-2' /> 190 sqm */}
                <span>{homeSize}</span>
                <span className="h-6 mx-1 items-center flex">{t(areaUnit.homeUnit)}</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="opacity-60 group-hover:opacity-80">
            <div className=" grid grid-flow-col auto-cols-max gap-1 pt-2 text-xs sm:text-xs">
              <div className="flex items-center">
                <span>{lotSize}</span>
                <span className="mx-1 items-center">{t(areaUnit.landUnit)}</span>
              </div>
            </div>
          </div>
        )}
        <div className="flex justify-between text-xs sm:text-sm text-deehiy transition-all duration-100 ease-in-out font-bold">
          {isPOR ? (
            <div className="text-deehiy font bold">{t('Price on Request')}</div>
          ) : (
            <NumericFormat
              value={
                filter?.isForRent
                  ? Number(monthlyRentPrice)
                  : listingPrice !== null
                  ? listingPrice
                  : Number(monthlyRentPrice)
              }
              thousandSeparator=" "
              displayType={'text'}
              prefix={isoCodeCurrency + ' '}
              suffix={filter?.isForRent || listingPrice === null ? ` / ${t('month')}` : ''}
            />
          )}
          {/* <p className="hidden sm:flex text-xs text-gray-300">{timeAgo(createdAt!)}</p> */}
          <span className="hidden text-xs whitespace-nowrap text-gray-300">
            {nFormatter(viewCount, 1)} views
          </span>
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;
