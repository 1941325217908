import React, { useState, useEffect } from 'react';
import Square from '../../components/square/Square';
// import InterestCarousel from '../../components/main/carousel/InterestsCarousel';
import OverlaySidebarLayout from '../../components/square/OverlaySidebarLayout';
import SquareFilter from '../../components/main/filter/SquareFilter';
import { useGlobal } from '../../contexts/global';
import { getPostsService } from '../../services/postService';
import { POST_TYPES } from '../../constants/constant';

type SquareFilterType = {
  selectedTypes: string[];
  interests: string[];
  keyword: string;
  fromNetwork: boolean;
};

const SquarePage = () => {
  const { keyword } = useGlobal();
  const initialSquareFilter = {
    selectedTypes: [POST_TYPES.GENERAL, POST_TYPES.DISCUSSION, POST_TYPES.ARTICLE],
    interests: [],
    keyword: keyword,
    fromNetwork: false,
  };
  const [dataList, setDataList] = useState<any[]>([]);
  const [filter, setFilter] = useState<SquareFilterType>(initialSquareFilter);
  const [tempFilter, setTempFilter] = useState(initialSquareFilter);

  const [filterState, setFilterState] = useState({ isInterestsOpen: false });
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isFilterOpen, setIsFilterOpen] = useState(window.innerWidth >= 768);
  const [count, setCount] = useState(0);

  // debounce
  const handleSearch = async (updatedFilter: SquareFilterType, currentPage: number) => {
    try {
      const res = await getPostsService({
        ...updatedFilter,
        page: currentPage,
      });

      const hasMorePosts = res.data.hasMore;
      if (currentPage === 1) {
        setDataList([...res.data.posts]);
      } else {
        setDataList((post) => [...post, ...res.data.posts]);
      }

      if (hasMorePosts) {
        setPage(page + 1);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error while fetching posts:', error);
    } finally {
    }
  };

  const handleShowResults = () => {
    handleSearch(tempFilter, 1);
    setFilter({ ...tempFilter }); // the temporary filter become the filter for scrolling
  };

  const fetchCount = async (updatedFilter: SquareFilterType) => {
    try {
      const res = await getPostsService(updatedFilter);
      setCount(res.data.totalCount);
    } catch (error) {
      console.error('Error while fetching posts:', error);
    } finally {
    }
  };

  // initial search
  useEffect(() => {
    handleSearch(tempFilter, 1);
  }, []);

  useEffect(() => {
    setFilter({ ...filter, keyword: keyword });
    setTempFilter({ ...tempFilter, keyword: keyword });
  }, [keyword]);

  useEffect(() => {
    fetchCount(tempFilter);
  }, [tempFilter]);

  const resetFilter = () => {
    setTempFilter(initialSquareFilter);
  };

  return (
    <div>
      <div className="hidden fixed top-32 right-52 bg-red-400 bg-opacity-60 p-3 rounded-lg">
        {dataList.length}|| hasmore :{hasMore.toString()}
      </div>
      {/* top filter carrousel */}
      {/* <div
        className="w-full top-2 sm:top-16 z-10 sm:px-20 pb-1 flex px-6 bg-[#f7f7f7] mx-auto sticky 
        shadow-md border border-transparent border-b-deehiy sm:border-none"
      >
        <InterestCarousel searchParams={searchParams} setSearchParams={setSearchParams} />
      </div> */}

      <OverlaySidebarLayout
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        // setIsFilterOpen={setIsFilterOpen}
        tempFilter={tempFilter}
        setTempFilter={setTempFilter}
        filterState={filterState}
        setFilterState={setFilterState}
        resetFilter={resetFilter}
        handleSearch={handleShowResults}
        count={count}
        Filter={SquareFilter}
      >
        <Square
          posts={dataList}
          hasMore={hasMore}
          handleSearch={() => handleSearch(filter, page)}
        />
      </OverlaySidebarLayout>
    </div>
  );
};

export default SquarePage;
