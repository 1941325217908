import React from 'react';
import { Navbar } from 'flowbite-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useScroll } from '../../../contexts/scroll';
import { useAuth } from '../../../contexts/auth';
import { useGlobal } from '../../../contexts/global';
import { POST_TYPES } from '../../../constants/constant';
import Modal2 from '../../listing/Modal2';
import { useTranslation } from 'react-i18next';

export const MobileTopNavigation = ({ isBannerVisible }: { isBannerVisible: boolean }) => {
  const { visible, isAtTop } = useScroll();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const { showModal, setShowModal } = useGlobal();

  const HandleCreatePost = (type: string) => {
    if (!currentUser) navigate('/login', { state: { redirectTo: location } });
    else {
      navigate(`/users/addpost/${type}`);
    }
  };

  const navStyle = `fixed top-${isBannerVisible && isAtTop ? '12' : '0'}
     ${visible ? 'translate-y-0' : 'translate-y-[-48px]'}
    left-0 right-0 bg-white z-40 transition-all duration-300 items-center flex shadow-lg`;

  const MobileSearchBtn = () => {
    return (
      <Link to="/search">
        <img src="/images/icons/icon-mobile/mobile-search-button.svg" className="w-7 h-7" />
      </Link>
    );
  };

  const ModalItem = ({ itemLabel, onClick, imageUrl }: any) => {
    return (
      <div
        className="flex gap-x-3 text-sm  py-4 sm:py-3 hover:bg-gray-100 hover:cursor-pointer items-center mx-4"
        onClick={onClick}
      >
        {imageUrl && <img src={imageUrl} alt="ico" className="w-8 h-8 sm:w-8 sm:h-8" />}
        {t(itemLabel)}
      </div>
    );
  };

  const PlusModalContent = () => {
    return (
      <>
        <ModalItem
          itemLabel="List property"
          onClick={() => navigate('/addproperty')}
          imageUrl="/images/icons/list-property-round.svg"
        />
        <ModalItem
          itemLabel="Hunt property"
          onClick={() => navigate('/users/addhunt')}
          imageUrl="/images/icons/hunt-property.svg"
        />
        <ModalItem
          itemLabel="New post"
          onClick={() => HandleCreatePost(POST_TYPES.GENERAL)}
          imageUrl="/images/icons/new-post.svg"
        />
        <ModalItem
          itemLabel="Start discussion"
          onClick={() => HandleCreatePost(POST_TYPES.DISCUSSION)}
          imageUrl="/images/icons/new-discussion.svg"
        />
        <ModalItem
          itemLabel="Write article"
          onClick={() => HandleCreatePost(POST_TYPES.ARTICLE)}
          imageUrl="/images/icons/new-article.svg"
        />
      </>
    );
  };

  const PlusRoundBtn = () => {
    return (
      <img
        title={t('Create listings, posts and hunts')}
        src="/images/icons/deehiy-plus.svg"
        className="w-7 h-7"
        onClick={() => setShowModal(true)}
      />
    );
  };

  return (
    <nav className={`${navStyle} h-12 `}>
      <div className="mx-auto z-20 px-4 sm:px-20 w-full">
        <div className="flex items-center justify-between">
          <div className="w-1/3">
            <Navbar.Brand onClick={() => navigate('/')}>
              <img src="/images/logo.jpg" className=" h-6 sm:h-9 " alt="Logo" />
            </Navbar.Brand>
          </div>
          <div className="flex w-1/3 justify-end gap-x-6 ">
            <MobileSearchBtn />
            <PlusRoundBtn />
          </div>
        </div>
      </div>
      <Modal2 showModal={showModal} setShowModal={setShowModal}>
        <PlusModalContent />
      </Modal2>
    </nav>
  );
};
