import React, { useState, useEffect, useRef } from 'react';
import LocationSearch from '../../steps/propertySteps/Location/LocationSearch';
import Input from '../../form/Input';
import GoogleMapReact from 'google-map-react';
import Toggle from '../../form/Toggle';
import Loader from '../../main/Loader';
import { FormProvider, useForm } from 'react-hook-form';
import { useProperty } from '../../../contexts/property';
import { useTranslation } from 'react-i18next';

const LocationContent = ({ modifyProperty }: any) => {
  const methods = useForm({
    mode: 'onTouched',
    // resolver: yupResolver(UserFormSchema),
  });
  const { currentProperty, setCurrentProperty } = useProperty();
  const { t } = useTranslation();

  const firstUpdate = useRef(true);

  const [showExactLocation, setShowExactLocation] = useState(currentProperty.showExactLocation);

  const [isEditingAddressDetails, setIsEditingAddressDetails] = useState(false);
  const [citySearched, setCitySearched] = useState(currentProperty.city);
  const [postalCode, setPostalCode] = useState(currentProperty.postalCode);
  const [streetNameHouseNumber, setStreetNameHouseNumber] = useState(
    currentProperty.streetNameHouseNumber
  );

  const [coordinates, setCoordinates] = useState({
    lat: currentProperty.latitude,
    lng: currentProperty.longitude,
  });
  const [marker, setMarker] = useState<any>();

  const [map, setMap] = useState<any>(null);
  const [maps, setMaps] = useState<any>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // place cursor on current location
  const initPosition = () => {
    if (currentProperty.latitude !== 0) {
      setCoordinates({
        lat: currentProperty.latitude,
        lng: currentProperty.longitude,
      });
    } else if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCoordinates({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });

        if (maps && map) {
          marker.setPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        }
      });
    }
  };
  // eslint-disable-next-line
  const onGoogleApiLoaded = (map: any, maps: any) => {
    setMap(map);
    setMaps(maps);
    setIsLoaded(true);
  };

  // place map on the location of the user
  useEffect(() => {
    initPosition();
  }, []);

  // init marker
  useEffect(() => {
    if (isLoaded && !marker) {
      var icon = {
        url: '/images/icons/map-pin.svg', // url
        scaledSize: new google.maps.Size(72, 72), // scaled size
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(36, 72), // anchor
      };

      setMarker(
        new maps.Marker({
          icon: icon,
          map,
          position: { lat: coordinates.lat, lng: coordinates.lng },
          title: t('drag and drop me'),
          draggable: true,
        })
      );
      map.setCenter({ lat: coordinates.lat, lng: coordinates.lng });
    }
  }, [map, maps, isLoaded]);

  useEffect(() => {
    if (marker) {
      maps.event.addListener(marker, 'dragend', function () {
        let coord = marker.getPosition();
        // let coord = marker.getCenter()
        if (coord) {
          setCoordinates({ lat: coord.lat(), lng: coord.lng() });
        }
      });

      map?.addListener('zoom_changed', function () {
        if (!currentProperty.showExactLocation) {
          if (map.zoom === 20) {
            marker.icon.scaledSize = new google.maps.Size(600, 600); // scaled size
            marker.icon.size = new google.maps.Size(600, 600);
            marker.origin = new google.maps.Point(0, 0); // origin
            marker.anchor = new google.maps.Point(300, 300); // anchor
          }
          if (map.zoom === 18) {
            marker.icon.scaledSize = new google.maps.Size(460, 460); // scaled size
            marker.icon.size = new google.maps.Size(460, 460);
            marker.origin = new google.maps.Point(0, 0); // origin
            marker.anchor = new google.maps.Point(230, 230); // anchor
          }
          if (map.zoom === 16) {
            marker.icon.scaledSize = new google.maps.Size(280, 280); // scaled size
            marker.icon.size = new google.maps.Size(280, 280);
            marker.origin = new google.maps.Point(0, 0); // origin
            marker.anchor = new google.maps.Point(140, 140); // anchor
          }
          if (map.zoom === 13) {
            marker.icon.scaledSize = new google.maps.Size(120, 120); // scaled size
            marker.icon.size = new google.maps.Size(120, 120);
            marker.origin = new google.maps.Point(0, 0); // origin
            marker.anchor = new google.maps.Point(60, 60); // anchor
          }
          if (map.zoom === 8) {
            marker.icon.scaledSize = new google.maps.Size(40, 40); // scaled size
            marker.icon.size = new google.maps.Size(40, 40);
            marker.origin = new google.maps.Point(0, 0); // origin
            marker.anchor = new google.maps.Point(20, 20); // anchor
          }
        }
        marker.setMap(map);
      });
    }
  }, [marker, currentProperty.showExactLocation]);

  useEffect(() => {
    if (marker && maps) {
      if (currentProperty.showExactLocation) {
        marker.setIcon({
          url: '/images/icons/map-pin.svg', // url
          scaledSize: new google.maps.Size(72, 72), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(36, 72), // anchor
        });
        // marker.setMap(map)
      } else {
        marker.setIcon({
          url: '/images/icons/map-circle.svg', // url
          scaledSize: new google.maps.Size(120, 120), // scaled size
          size: new google.maps.Size(120, 120),
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(60, 60), // anchor
        });
        // map.setZoom(13)
      }
    }
  }, [currentProperty.showExactLocation]);

  useEffect(() => {
    const updatedPosition = { ...currentProperty };
    updatedPosition.latitude = coordinates.lat;
    updatedPosition.longitude = coordinates.lng;
    setCurrentProperty(updatedPosition);
  }, [coordinates]);

  useEffect(() => {
    setCurrentProperty({ ...currentProperty, showExactLocation: showExactLocation });
  }, [showExactLocation]);

  useEffect(() => {
    if (!isEditingAddressDetails)
      setCurrentProperty({
        ...currentProperty,
        city: citySearched,
        streetNameHouseNumber: streetNameHouseNumber,
        postalCode: postalCode,
      });
  }, [isEditingAddressDetails]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    console.log('updating location ...');
    setIsLoading(true);
    modifyProperty(currentProperty).then(() => {
      setIsLoading(false);
    });
  }, [
    currentProperty.showExactLocation,
    currentProperty.latitude,
    currentProperty.city,
    currentProperty.streetNameHouseNumber,
    currentProperty.postalCode,
  ]);

  return (
    <div className="h-[24vh] w-full sm:w-3/4">
      <div className="flex flex-col text-sm font-light pb-2">
        {isLoading && <Loader />}
        <span className="font-bold">{t('Map details')}</span>
        {t("This is your property's current location. Drag to adjust the pin manually.")}
      </div>

      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLEMAP_API_KEY }}
        defaultCenter={coordinates}
        center={coordinates}
        defaultZoom={16}
        yesIWantToUseGoogleMapApiInternals
        // eslint-disable-next-line
        onGoogleApiLoaded={({ map, maps }: any) => onGoogleApiLoaded(map, maps)}
      />

      <div className="flex">
        <div className="flex-col w-7/8 mt-3">
          <p className="text-sm">{t('Show exact location')}</p>
          <p className="font-light text-xs ">{t('exact_loc_tip')}</p>
        </div>
        <div className="flex justify-center items-center my-auto mx-auto">
          <Toggle setIsToggled={setShowExactLocation} isToggled={showExactLocation} />
        </div>
      </div>

      {/* <hr className="my-5" /> */}

      <FormProvider {...methods}>
        <form>
          <div className="w-full md:w-full">
            <div className="pt-2 pb-4">
              <div className="flex justify-between pb-2">
                <span className="font-light">{t('Address details')}</span>
                <button
                  type="button"
                  className="text-deehiy font-bold"
                  onClick={() => {
                    setIsEditingAddressDetails((p) => !p);
                  }}
                >
                  {!isEditingAddressDetails ? t('Edit') : t('Save')}
                </button>
              </div>
              <div>
                {!isEditingAddressDetails ? (
                  <div className="flex gap-3 font-bold">
                    <div>{currentProperty.streetNameHouseNumber},</div>
                    <div>{currentProperty.city},</div>
                    <div>{currentProperty.postalCode}</div>
                  </div>
                ) : (
                  <div className="flex flex-col pb-6 ">
                    <LocationSearch
                      label={t('city')}
                      locationString={citySearched}
                      setLocationString={setCitySearched}
                    />
                    <div className="flex gap-4">
                      <Input
                        label={t('street name house number')}
                        name="streetNameHouseNumber"
                        id="streetNameHouseNumber"
                        type="text"
                        value={streetNameHouseNumber}
                        onChange={(e: any) => {
                          setStreetNameHouseNumber(e.target.value);
                        }}
                      />

                      <Input
                        label={t('Postal code')}
                        name="postalCode"
                        id="postalCode"
                        type="text"
                        value={postalCode?.toString()}
                        onChange={(e: any) => {
                          setPostalCode(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default LocationContent;
