import React, { useState, useEffect, useMemo } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from '@tanstack/react-table';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { BsChevronExpand } from 'react-icons/bs';
import { DropdownDotsBtn } from '../../../main/community/Community';
import { CustomDropwdown, DropdownItem } from '../../../main/navigation/Navigation';
import { useHunt } from '../../../../contexts/hunt';
import { FetchedFeature, Hunt } from '../../../../types/types';
import { adjustImageSize } from '../../../../utils/adjustImageSize';
import { getPropertiesByHuntIdService, updateMatchService } from '../../../../services/huntService';
import { formatDate } from '../../../../utils/dateFormatter';
import ItemIcon from '../../../main/ItemIcon';
import { useTranslation } from 'react-i18next';

const columnHelper = createColumnHelper<Hunt>();

const RemoveDropdown = ({ id, handleRemove }: { id: number; handleRemove: any }) => {
  return (
    <CustomDropwdown btn={<DropdownDotsBtn />}>
      <DropdownItem itemLabel="remove" onClick={() => handleRemove(id)} />
    </CustomDropwdown>
  );
};

const FeaturesDropdown = ({
  huntFeatures,
  listingFeatures,
}: {
  huntFeatures: FetchedFeature[];
  listingFeatures: FetchedFeature[];
}) => {
  const tickAvailablePath = '/images/icons/huntingBoard/tick-available.svg';
  const tickNotAvailablePath = '/images/icons/huntingBoard/tick-not-available.svg';
  const [showAllFeatures, setShowAllFeatures] = useState(false);

  function countItemsInCommon(arr1: FetchedFeature[], arr2: FetchedFeature[]): string {
    const commonItems = arr1?.filter((item1) =>
      arr2.some((item2) => item2.featureTitle === item1.featureTitle)
    );

    const itemsInCommon = commonItems?.length;

    return `${itemsInCommon}/${arr1?.length}`;
  }

  return (
    <CustomDropwdown
      Btn={
        <button
          type="button"
          title="Compare the features"
          className="active:text-gray-500 hover:cursor-pointer hover:text-deehiy "
        >
          {countItemsInCommon(listingFeatures, huntFeatures)}
        </button>
      }
    >
      <>
        <div className="text-xs grid grid-cols-1 py-2 px-4 ">
          {huntFeatures?.length > 0 &&
            listingFeatures?.map((f) => (
              <div className="grid grid-cols-3 justify-center items-center">
                <ItemIcon size={7} Icon={f.SVG} />
                <span>{f.featureTitle}</span>
                {huntFeatures.some(({ featureTitle }) => f.featureTitle === featureTitle) ? (
                  <img className="w-7 h-7" alt="tick" src={tickAvailablePath} />
                ) : (
                  <img className="w-7 h-7" alt="tick" src={tickNotAvailablePath} />
                )}
              </div>
            ))}
        </div>
        {!showAllFeatures && (
          <span
            className="text-deehiy justify-center flex font-bold cursor-pointer"
            onClick={() => setShowAllFeatures(true)}
          >
            Show all features <BiChevronDown className="w-5 h-5" />
          </span>
        )}
        {showAllFeatures && (
          <div>
            <div className="text-xs grid grid-cols-1 py-4 px-4">
              {huntFeatures
                .filter(
                  ({ featureTitle }) =>
                    !listingFeatures.some((f) => f.featureTitle === featureTitle)
                )
                .map((f) => (
                  <div
                    key={f.featureTitle}
                    className="grid grid-cols-3 justify-center items-center"
                  >
                    <ItemIcon size={7} Icon={f.SVG} />
                    <span className="col-span-2">{f.featureTitle}</span>
                  </div>
                ))}
            </div>{' '}
            <span
              className="text-deehiy justify-center flex font-bold cursor-pointer"
              onClick={() => setShowAllFeatures(false)}
            >
              Show less features <BiChevronUp className="w-5 h-5" />
            </span>
          </div>
        )}
      </>
    </CustomDropwdown>
  );
};

const MatchTable = () => {
  // const rerender = useReducer(() => ({}), {})[1];
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [data, setData] = useState<any>(() => []);
  const { currentHunt } = useHunt();
  const appToken = localStorage.getItem('@App:token');

  const handleRemove = (id: number) => {
    updateMatchService({ matchId: id, status: 'inactive' }).then(() => {
      // setData((prevData: any) => prevData.filter((match: any) => match.id !== id));
      if (currentHunt.huntId) {
        getPropertiesByHuntIdService(currentHunt?.huntId).then((res) => {
          setData(() => [...res.data]);
        });
      }
    });
  };

  const chatHandler = async (info: any) => {
    const firstName = info?.row?.original?.user?.firstName;
    const id = info?.row?.original?.userId;
    window.open(
      `${
        process.env.REACT_APP_MESSENGER_URL
      }?token=${appToken}&id=${id}&username=${firstName}&userSpecificChat=${true}`,
      '_blank'
    );
  };

  const columns = useMemo<ColumnDef<Hunt, any>[]>(() => {
    const baseColumns = [
      columnHelper.accessor('images', {
        id: 'image',
        header: () => <span className="text-xs sm:text-md ">{t('Listings')}</span>,
        cell: (info) => (
          <img
            src={adjustImageSize(info.renderValue()[0].url, 300)}
            alt=""
            className="my-2 sm:my-3 pr-0 max-w-[50px] sm:max-w-[150px] aspect-video"
          />
        ),
      }),
      columnHelper.accessor((row) => row.propertyTitle, {
        id: 'propertyTitle',
        cell: (info) => (
          <div
            title="open listing page"
            className="text-xs sm:text-md line-clamp-2 font-bold text-left cursor-pointer hover:underline"
            onClick={() => window.open(`/properties/${info.row.original.propertyId}`, '_blank')}
          >
            {info.getValue()}
          </div>
        ),
        enableSorting: false,
        header: '',
      }),
      columnHelper.accessor('match', {
        id: 'submitted',
        header: () => (
          <span className="w-full text-center text-sm sm:text-md">{t('Submitted')}</span>
        ),
        cell: (info) => (
          <div className="w-full text-center">{formatDate(info.renderValue()[0].updatedAt)}</div>
        ),
      }),
      columnHelper.accessor('match', {
        id: 'percent',
        header: () => (
          <span className="text-center text-xs sm:text-md whitespace-nowrap">
            {t('Match Rate')}
          </span>
        ),
        cell: (info) => (
          <div className="justify-center text-xs sm:text-md flex w-full">
            {info.renderValue()[0].percent}%
          </div>
        ),
      }),
      columnHelper.accessor('features', {
        id: 'features',
        header: () => (
          <span className="w-full text-center text-sm sm:text-md">{t('Features')}</span>
        ),
        cell: (info) => (
          <div className="justify-center flex">
            <FeaturesDropdown
              listingFeatures={currentHunt.features as FetchedFeature[]}
              huntFeatures={info.renderValue() as FetchedFeature[]}
            />
          </div>
        ),
      }),
      columnHelper.accessor('huntId', {
        header: '',
        cell: (info) => (
          <div className="flex justify-center">
            <button
              title="Contact the owner"
              className="primary-btn  m-0 rounded-full flex gap-x-2"
              onClick={() => chatHandler(info)}
            >
              <img src="/images/icons/chat.svg" className="h-5 w-5" /> {t('Chat')}
            </button>
          </div>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor('match', {
        id: 'removeButton',
        header: '',
        cell: (info) => (
          <RemoveDropdown handleRemove={handleRemove} id={info.renderValue()[0].id} />
        ),
        enableSorting: false,
      }),
    ];

    const mobileColumns = baseColumns.filter(
      (col: any) => col.id !== 'submitted' && col.id !== 'features'
    ) as ColumnDef<Hunt, any>[];

    return window.innerWidth <= 768 ? mobileColumns : baseColumns;
  }, []);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    console.log(currentHunt);
    if (currentHunt.huntId) {
      getPropertiesByHuntIdService(currentHunt?.huntId).then((res) => {
        setData(() => [...res.data]);
      });
    }
  }, [currentHunt]);

  return (
    <div>
      <div className="px-1 sm:px-3 pt-3 ring-1 rounded bg-white ring-deehiy">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th className="pb-3" key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none flex items-center'
                            : 'hidden',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <BiChevronUp className="w-5 h-5" />,
                          desc: <BiChevronDown className="w-5 h-5" />,
                        }[header.column.getIsSorted() as string] ?? (
                          <BsChevronExpand className="ml-1 w-4 h-4 text-gray-400" />
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                className="last:border-b-transparent border border-x-transparent border-y-deehiy"
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MatchTable;
