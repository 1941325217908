import React, { useState, useEffect } from 'react';
import { useHunt } from '../../../contexts/hunt';
import { useAuth } from '../../../contexts/auth';
import { useParams, useNavigate } from 'react-router-dom';
import MatchTable from './table/MatchTable';
import Loader from '../../main/Loader';
import TakeMeBack from '../../main/navigation/TakeMeBack';
import { useTranslation } from 'react-i18next';

const MatchesPerHunt = () => {
  const { id } = useParams();
  const { fetchHuntByIdContext, setCurrentHuntContext, currentHunt } = useHunt();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      // authentication check
      fetchHuntByIdContext(Number(id))
        .then((hunt: any) => {
          if (hunt.userId !== currentUser?.id) {
            alert('you are not allowed to edit this hunt');
            navigate('/');
          } else {
            setCurrentHuntContext(hunt);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  }, [id]);

  if (isLoading) return <Loader />;

  return (
    <div className="container mx-auto h-screen">
      <div className="py-4 sm:py-12 space-y-6 sm:space-y-12 ">
        <TakeMeBack />
        <div className="text-xl sm:text-2xl font-bold w-full ">{currentHunt.location}</div>

        <div className="text-sm font-bold sm:text-md pt-2 sm:pt-4">
          {t('listings_received', { count: currentHunt?.matches?.length || 0 })}
          {/* {currentHunt?.matches?.length && currentHunt?.matches?.length > 1 && 's'} */}
          <div className="text-sm font-normal pt-2 sm:pt-4">
            {t('Review these listings and reach out to the listing owner')}
          </div>
        </div>
      </div>
      <MatchTable />
      {/* matches={currentHunt.matches} */}
    </div>
  );
};

export default MatchesPerHunt;
