import React from 'react';
import { useTranslation } from 'react-i18next';

const FilterSearchBtn = ({ resetFilter, handleSearch, count, setIsFilterOpen }: any) => {
  const { t } = useTranslation();
  const isMobile = window.innerWidth <= 768;

  const handleClick = () => {
    handleSearch();
    if (isMobile) setIsFilterOpen(false);
  };

  return (
    <div className=" flex w-full justify-between px-4">
      <button
        className="hover:underline text-sm semibold border-none outline-none"
        onClick={resetFilter}
      >
        {t('Clear all')}
      </button>
      <button
        onClick={handleClick}
        className="primary-btn mr-8 sm:mr-0 text-sm py-2 px-3 rounded-md"
      >
        {t(count > 999 ? 'Show 999+ results' : 'Show results', { count })}
      </button>
    </div>
  );
};

export default FilterSearchBtn;
